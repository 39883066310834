.c-confirm__block {
  .a-content__details__header {
    margin-top: 28px;
  }
}

.confirm-separated-tickets__main-info {
  display: flex;
  gap: 35px;

  .c-confirm__header__content {
    min-height: 190px;
    position: relative;
  }

  .c-confirm__header__content__col {
    float: none;
    line-height: 30px;
  }

  .c-confirm__header__content {
    padding: 10px 10px 10px 15px;
  }

  .c-confirm__header {
    flex-grow: 1;
  }

  .btn-link {
    margin: 15px 0 45px;
    font-size: 14px;
    background: rgba(255,255,255,0.8);
    border-radius: 4px;

    &.highlight-red {
      border: 1px solid @red;

      &:hover {
        color: @white;
        background: @red;
      }

      .btn__spinner {
        border-color: @red;
        border-right-color: transparent;
      }
    }
  }
}

.confirm-separated-tickets {
  .c-confirm__block__inner-header, .c-confirm__payment-information-ticket:not(:first-of-type) {
    margin-top: 20px;
  }

  .c-confirm__header {
    display: flex;
    flex-direction: column;
  }

  .c-confirm__header__content {
    flex-grow: 1;
  }

  .c-confirm__header__content {
    display: flex;
    flex-direction: column;
  }

  .c-confirm__header__content__cancel-btn {
    flex-direction: row;
    display: flex;
    flex-grow: 1;
    & > button {
      align-self: flex-end;
    }
  }

  .c-confirm__header__content__reservation-reference {
    border-top: 1px dashed #d4d5d6;
    padding: 8px 0 0 16px;
    margin-left: -14px;
    margin-right: -8px;
    font-size: 12px;
    color: @gray;
    position: absolute;
    bottom: 10px;
    width: 100%;
  }
}

.c-confirm__important-information-ticket {
  font-weight: bold;
}

.c-confirm__payment-information-ticket {
  font-weight: bold;
  font-size: 14px;
}

.c-confirm__basis-codes-ticket {
  font-weight: normal;
}
