// Agent (AFT) Home page

.ag-agent-wrapper {
  position: relative;
  width: 1140px;
  margin: 7vh auto 0;
  background: @white;
  border-radius: 8px;
  box-shadow:
  3.1px 2.4px 2.7px rgba(0, 0, 0, 0.036),
  7.8px 6.2px 6.9px rgba(0, 0, 0, 0.048),
  15.9px 12.6px 14.2px rgba(0, 0, 0, 0.056),
  32.9px 25.9px 29.2px rgba(0, 0, 0, 0.065),
  90px 71px 80px rgba(0, 0, 0, 0.09);

  .block-title {
    margin-top: 0;
    padding-bottom: 9px;
    font-size: 18px;
  }
}

.ag-form__header {
  margin: -10px 0 14px;
  border-bottom: 1px solid @gray-border;
}

.ag-row {
  margin-bottom: 9px;
}

.ag-row__header {
  margin: -1px 0 8px;

  h5 {
    font-weight: bold;
    margin: 0 0 2px;
  }

  & ~ .alert {
    margin: 0 0 10px !important;
  }
}

.ag-left-block {
  position: relative;
  float: left;
  width: 460px;
  padding: 25px 10px 10px 30px;

  .ag-home-form {
    width: 425px;
  }

  .pnr-search-error {
    color: @red;
  }

  .control-label {
    font-weight: normal !important;
  }

  .alert {
    margin-top: -12px;
    padding: 6px 6px 6px 25px;
    font-size: 12px;

    .glyphicon {
      position: absolute;
      left: 7px;
      top: 8px;
    }
  }

  .tags-input .dropdown-menu {
    max-width: none;
  }
}

.ag-right-block-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 650px;
  background: @grayBg;
  border-left: 1px solid @grayBorder;
}

.ag-right-block {
  position: relative;
  float: right;
  width: 650px;
  padding: 25px 40px 15px 27px;
  z-index: 3;

  .tabs-inner {
    margin: -25px -40px 16px -26px;
    padding: 0;
    background: @white;
    border-radius: 0 8px 0 0;
  }

  .tabs-inner__controls {
    display: flex;
    margin: 11px 0 0 23px;
  }

  .tabs-inner__item {
    float: none;
    align-content: center;
    min-width: 140px;
    min-height: 37px;
    padding: 9px 13px;
    font-weight: bold;
    text-transform: uppercase;
    background: #f8fafb;
  }

  .tabs-inner__item--active {
    border-bottom-color: #edf2f5;
    background: #edf2f5;
  }

  .tabs-inner__item--disabled {
    font-size: 12px;
    padding-top: 2px;
    padding-bottom: 0;
    border: 1px dashed #d4d5d6;
    border-bottom-style: solid;
    background: #f8f8f8;
    pointer-events: none;

    .text-gray {
      font-size: 11px;
      font-weight: normal;
      text-transform: none;
    }
  }

  .lnk-options {
    display: inline-block;
    margin: 5px 0;
  }

  .row__toggle__label {
    margin: 0;
  }
}

.ag-home-form__hr {
  margin: 4px 0 14px 0;
  border-top-style: dashed;
}

.inactive-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255,255,255,0.6);
  z-index: 15;
  border-radius: 0 8px 8px 0;
}

.ag-left-block__offset {
  margin: 5px 0 10px 98px;

  .btn {
    min-width: 105px;
  }
}

.ag-home-form {
  margin: 15px 0 0;

  .form-group {
    .clearfix();
    margin-bottom: 12px;
  }

  .control-label {
    float: left;
    width: 98px;
    margin-top: 5px;
    padding-right: 0;
    color: #666;
    font-weight: bold;
  }

  .form-group--block-labels {
    .control-label {
      padding-right: 15px;
    }
  }

  .dropdown-menu > li > a {
    padding: 3px 11px;
  }

  .row .ag-home-form__hr {
    margin-right: 15px;
    margin-left: 15px;
  }
}

.ag-home-form--wide-labels {
  .control-label {
    width: 25%;
  }

  .ag-home-daterange {
    .form-control {
      width: 204px;
    }

    .input-daterange {
      &:before {
        left: 180px;
      }
    }
  }

  .ag-home-daterange-single .dropdown {
    width: 204px;

    &:before {
      left: 180px;
    }
  }

  .ag-home__label-offset {
    margin-left: 153px;
  }
}

.ag-info-table {
  .glyphicon-info-sign {
    color: @grayDark;
  }
}

.ag-home-daterange {
  .ag-home-daterange-single {
    float: left !important;
  }

  .form-control {
    width: 219px;
    border-radius: @input-border-radius !important;

    &:last-of-type {
      float: right;
    }
  }

  .placeholder-wrap {
    float: right;

    &:first-child {
      float: left;
    }
  }

  .input-daterange {
    &:before,
    &:after {
      .glyphicons();
      content: "\E046";
      position: absolute;
      top: 8px;
      font-size: 15px;
      color: @gray;
      z-index: 3;
      pointer-events: none;
    }

    &:before {
      left: 194px;
    }

    &:after {
      right: 9px;
    }
  }

  .input-group-addon {
    visibility: hidden;
  }
}

.ag-home-daterange-single {
  .dropdown {
    &:before {
      .glyphicons();
      content: "\E046";
      position: absolute;
      top: 8px;
      left: 194px;
      font-size: 15px;
      color: @gray;
      z-index: 3;
      pointer-events: none;
    }
  }
}

.ag-destination {
  position: relative;

  .checkbox-rev {
    margin-bottom: 0;
  }
}

.ag-destination__range {
  cursor: pointer;
  width: 100%;
  height: 28px;
  color: @blue;
  border: none;
  background: @white;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:-ms-expand {
    display: none;
  }

  .value {
    height: 28px;
    padding-left: 6px;
    line-height: 27px;
    color: @blue;
    text-align: right;
    border: none !important;
    background: none;

    &:after {
      right: 7px;
      top: 12px;
      border-top: 4px solid @blue;
    }
  }

  &.custom-select-focused {
    .value {
      border: none;
    }
  }
}

.ag-destination__wrapper {
  overflow: hidden;
  position: absolute;
  right: 21px;
  top: 2px;
  z-index: 10;
}

.ag-home-guests {
  margin-bottom: 14px !important;

  .custom-select {
    width: 218px;
  }
}

.ag-home-form__extra {
  margin: 9px 0 10px;
  padding-top: 15px;
  border-top: 1px solid @grayBorder;

  .form-group__label-offset {
    &:before {
      width: 99px;
    }

    .form-group {
      margin-bottom: 5px;
    }

    .checkbox-rev {
      margin-bottom: 0;
    }
  }
}

.ag-home-form__control {
  margin: 23px 0 18px 0;

  .btn {
    min-width: 120px;
  }
}

.ag-home__label-offset {
  margin-left: 139px;

  .checkbox-rev {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.ag-home-form__control--top {
  margin: -10px 0 -6px;
}

.ag-home-form__control--bottom {
  .clearfix();
  margin: 18px 0 8px;
  padding: 21px 0 0;
  border-top: 1px dashed @gray-border;

  .btn {
    padding: 9px 22px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.ag-info-table {
  width: 100%;
  margin: 15px 0;

  th {
    width: 160px;
    padding: 0 5px;
    color: darken(@gray, 2%);
    text-align: left;
    font-weight: normal;
    white-space: nowrap;
    background: darken(@grayBg, 2%);

    .tooltip {
      white-space: normal;
    }
  }

  td {
    padding: 4px 5px 4px 10px;
  }

  tr {
    border-bottom: 1px solid @grayBorder;

    &:first-child {
      border-top: 1px solid @grayBorder;
    }
  }
}

.ag-table {
  font-size: 12px;

  th, td {
    padding: 4px 0 !important;
    border-top: none !important;
    border-bottom: 1px solid @table-border-color !important;
  }

  th {
    width: 179px;
  }

  .glyphicon-info-sign {
    margin-left: 3px;
    color: @gray-darker;
  }
}

.ag-table__cell-col {
  width: 240px;
  word-wrap: break-word;

  & + & {
    margin-top: 4px;
    padding-top: 4px;
    border-top: 1px dotted @gray-border;
  }

  .text-tag {
    margin-right: 6px;
  }

  .btn-link {
    line-height: 1;
  }
}

.ag-table__cell-edit {
  display: table-cell;
  float: right;

  & + .ag-table__cell-col {
    display: table-cell;
    width: auto;
    padding-right: 5px;
  }
}

.ag-block-list {
  margin: -4px 0 15px;
  padding: 0 0 15px;
  border-bottom: 1px solid @gray-border;
}

.ag-block-list__header {
  padding: 3px 0 20px;

  .ag-table {
    margin-bottom: 0;
  }
}

.ag-block-list__title {
  margin: 0 0 14px;
  font-size: 13px;
  font-weight: bold;

  .btn {
    margin-top: -1px;
  }
}

.ag-block-list__item {
  position: relative;
  margin-bottom: 7px;
  padding: 5px 5px 5px 14px;
  font-size: 12px;
  border-left: 3px solid @gray;
  background: @gray-bg;

  &:hover {
    .ag-block-list__control {
      display: block;
    }
  }

  .table {
    margin-bottom: 0;

    th,
    td {
      border: none;
      padding: 2px 0;
    }

    th {
      width: 44%;
    }
  }
}

.ag-block-list__alert {
  display: block;
  margin: 4px 0;
  font-size: 11px;
  color: @red;
}

.ag-block-list__control {
  display: none;
  position: absolute;
  top: 0;
  right: 2px;

  .btn {
    position: relative;
    top: 6px;
    margin: 0 4px 0 0;
    padding: 2px 4px;
    font-size: 11px;
    min-width: 55px;
    background: rgba(255,255,255,0.65);
    border: none;
    border-radius: 2px;
    box-shadow: 0 0 7px rgba(0,0,75,0.06) !important;

    .glyphicon-pencil {
      margin-right: 4px;
      margin-left: -2px;
    }
  }
}

.ag-block-list__danger-btn {
  color: @red;

  &:hover {
    color: darken(@red, 15%);
  }
}

// Dropdown section headers

.dropdown-header {
  display: block;
  margin: 3px 0;
  padding: 4px 10px 4px;
  font-weight: bold;

  &:first-child {
    margin-top: 0;
  }
}

.form-warning {
  float: right;
  width: 482px;
  margin-right: 18px;
  margin-bottom: 10px;
  padding: 4px 9px;
  line-height: 1.45;
  font-size: 12px;
  color: #9b5b2b;
  background: #ffe9af;
  border: 1px solid #ffcfa0;
  border-radius: 3px;
}

.search-warning {
  float: none;
  width: auto;
  margin-left: 10px;
  margin-right: 14px;
}

.pnr-message {
  float: left;
  width: 490px;
  height: 52px;
  margin-top: -4px;
  margin-left: 240px;
  padding: 5px 12px 0 18px;
  color: @white;
  white-space: nowrap;
  background: @red;

  .btn {
    color: @white;
    margin: 4px 0 0;
    background: darken(@red, 20%);
    border: 1px solid darken(@red, 20%);

    &:hover {
      background: darken(@red, 30%);
    }
  }

  & + .navbar__acc-link {
    position: absolute;
    margin-left: 160px;
  }
}

.pnr-message__header {
  margin-top: 2px;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
}

.pnr-message__item {
  margin-right: 8px;
  font-size: 12px;
}

.ta-warning {
  position: relative;
  margin-bottom: -4px;
  padding: 3px 15px 5px;
  font-size: 12px;
  text-align: center;
  color: darken(@orange, 41%);
  background: lighten(@yellow, 35%);
  border-bottom: 1px dashed darken(@orange, 41%);
  z-index: 4;
}

.ta-warning__text {
  color: darken(@orange, 38%);
  font-size: 11px;
}

.ta-warning__header {
  margin: 1px 0 2px;
  font-weight: bold;
  text-transform: uppercase;
}

.ag-home-form__title {
  font-size: 12px;
  padding-left: 170px;
}

.ag-home-form__title__item {
  float: left;
  width: 202px;
  margin-bottom: 10px;
  padding-bottom: 5px;
  color: @gray-dark;
  font-weight: bold;
  border-bottom: 1px solid @gray-border;

  &:last-child {
    margin-left: 25px;
  }
}

.ag-form-group__split {
  display: flex;
  margin-right: 14px;

  .form-group {
    flex-grow: 1;

    .control-label {
      width: 168px;
    }

    &:last-child:not(:only-child) {
      flex-grow: 0;

      .control-label {
        display: none;
      }
    }
  }

  .col-xs-5 {
    width: 204px;
    padding: 0;
  }

  .ag-home__label-offset {
    margin-left: 168px;
  }
}

.ag-form-control__split {
  display: flex;

  .form-control {
    min-width: 0;

    & + .form-control,
    & + .custom-select {
      margin-right: 0;
      margin-left: 10px;
    }
  }
}

.ag-home-form__switcher {
  margin: -5px 15px 18px 15px;
  padding-left: 155px;
  border-bottom: 1px solid @gray-border;

  .btn {
    position: relative;
    margin: 0 15px -1px 0;
    padding: 8px 12px 8px 30px;
    color: @gray-dark;
    background: transparent;
    border-radius: 0;

    &:hover {
      color: @gray-darker;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 7px;
      top: 50%;
      width: 14px;
      height: 14px;
      margin-top: -7px;
      border: 1px solid @gray-border;
      background: @white;
      border-radius: 10em;
    }
  }

  .ag-home-form__switcher--active {
    color: @gray-darker;
    font-weight: bold;
    border-bottom: 3px solid @orange;

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 10px;
      top: 50%;
      width: 8px;
      height: 8px;
      margin-top: -4px;
      background: @gray-dark;
      border-color: @gray-dark;
      border-radius: 10em;
    }
  }
}

.ag-mode__selector {
  display: flex;
  justify-content: flex-end;
  margin-top: -10px;

  .control-label {
    display: none;
  }

  .custom-select {
    width: auto !important;
    margin-right: 0;
  }

  .value {
    padding: 0 26px 0 14px;
    text-align: right;
    font-weight: bold;
    border: none !important;
    background: rgba(255,255,255,0.6);
  }
}

.ag-mode__multi-wrapper {
  position: relative;
  margin-bottom: 15px;
  padding: 15px 20px 5px;
  background: rgba(255,255,255,0.8);
  box-shadow: 0 2px 5px rgba(113,115,132,0.18);
  border-radius: 6px;

  &:after {
    content: "";
    display: block;
    position: absolute;
    right: -30px;
    top: 0;
    bottom: 0;
    width: 30px;
    z-index: 5;
  }

  &:hover {
    .ag-mode__control {
      display: block;
    }
  }

  .form-group {
    margin-bottom: 8px;
  }

  .ag-form-group__split {
    .control-label {
      width: 159px;
    }

    .ag-home__label-offset {
      margin-left: 159px;
    }
  }
}

.ag-mode__control {
  display: none;
  position: absolute;
  top: 0px;
  right: -38px;
  padding: 8px;
  font-size: 14px;
  z-index: 10;
}

.ag-mode__footer {
  margin: 1px 0 9px;
  text-align: right;

  .btn-link {
    padding-right: 0;
    padding-left: 0;
    font-size: 14px;
    font-weight: bold !important;
  }
}
