@-webkit-keyframes outline-spinner-color {
  0% {color: @blue}
  50% {color: @green}
  100% {color: @blue}
}

@keyframes outline-spinner-color {
  0% {color: @blue}
  50% {color: @green}
  100% {color: @blue}
}

@-webkit-keyframes outline-spinner {
  0%   {-webkit-transform: rotate(0deg);}
  100% {-webkit-transform: rotate(360deg);}
}

@keyframes outline-spinner {
  0%   {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

@-webkit-keyframes preloader-background {
  0% {background: #596771}
  50% {background: darken(@blue, 20%)}
  100% {background: #596771}
}

@keyframes preloader-background {
  0% {background: #596771}
  50% {background: darken(@blue, 20%)}
  100% {background: #596771}
}

@-webkit-keyframes preloader-bar {
  0%, 40%, 100% {-webkit-transform: scaleY(0.06);}
  20% {-webkit-transform: scaleY(1.0);}
}

@keyframes preloader-bar {
  0%, 40%, 100% {transform: scaleY(0.06);}
  20% {transform: scaleY(1.0);}
}

@-webkit-keyframes preloader-text-switch {
  0%, 14%, 100% {-webkit-transform: translate3d(0,0,0);}
  16%, 31% {-webkit-transform: translate3d(0,-20px,0);}
  33%, 47% {-webkit-transform: translate3d(0,-40px,0);}
  49%, 64% {-webkit-transform: translate3d(0,-60px,0);}
  66%, 81% {-webkit-transform: translate3d(0,-40px,0);}
  83%, 98% {-webkit-transform: translate3d(0,-20px,0);}
}

@keyframes preloader-text-switch {
  0%, 14%, 100% {transform: translate3d(0,0,0);}
  16%, 31% {transform: translate3d(0,-20px,0);}
  33%, 47% {transform: translate3d(0,-40px,0);}
  49%, 64% {transform: translate3d(0,-60px,0);}
  66%, 81% {transform: translate3d(0,-40px,0);}
  83%, 98% {transform: translate3d(0,-20px,0);}
}


.modal--hotel-preloader {
  .modal-dialog {
    width: 560px;
    margin-top: 0 !important;
    top: 43%;
  }

  .modal-body {
    padding: 16px;
  }

  .row {
    margin: 0;
  }
}

.hotel-preloader__spinner-wrapper {
  float: right;
  position: relative;
  width: 80px;
  height: 80px;
  margin: -2px -4px 0 0;
}

.hotel-preloader__spinner-circle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid rgba(255,255,255,0);
  border-left-color: @orange;
  border-bottom-color: @orange;
  border-radius: 50em;
  .animation(outline-spinner 1.3s linear infinite);
}

.hotel-preloader__spinner-circle:nth-of-type(1) {
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  border: 1px solid transparent;
  border-left-color: @blue;
  border-bottom-color: @blue;
  .animation(outline-spinner 2.2s linear infinite);
}

.hotel-preloader__spinner-circle:nth-of-type(2) {
  top: 6px;
  right: 6px;
  bottom: 6px;
  left: 6px;
  border: 1px solid transparent;
  border-right-color: @green;
  .animation(outline-spinner 1.5s linear infinite);
}

.hotel-preloader__spinner-icon {
  position: absolute !important;
  left: 26px;
  top: 24px !important;
  line-height: 1 !important;
  font-size: 28px;
  .animation(outline-spinner-color 5s ease-in infinite);
}

.hotel-preloader__message {
  margin: 10px 0 0;
  font-size: 20px;
  line-height: 1.3;
}

//
// Search preloader


.search-preloader {
  width: 500px;
  margin: 160px auto 20px;
  padding: 0 0 25px;
  text-align: center;
  box-shadow: 0 0 80px -25px rgba(100,0,0,0.2);
}

.search-preloader__title {
  padding: 9px 0 8px;
  font-size: 14px;
  font-weight: bold;
  color: @white;
  background: #596771;
  text-transform: uppercase;
  border-bottom: 1px dashed @gray-border;
  .animation(preloader-background 10s linear infinite);
}

.search-preloader__text-wrapper {
  height: 20px;
  margin: 24px 0 0;
  overflow: hidden;
}

.search-preloader__text {
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  list-style: none;
  .animation(preloader-text-switch 13s linear infinite);
}

.search-preloader__bar {
  width: 50px;
  height: 35px;
  margin: 30px auto 0;
}

.search-preloader__bar-item {
  float: left;
  width: 8px;
  height: 100%;
  margin-left: 2px;
  background: @gray-dark;
  .animation(preloader-bar 1.9s ease-in-out infinite);

  &:nth-of-type(1) {
    background: #a4de33;
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  &:nth-of-type(2) {
    background: #de7a33;
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }

  &:nth-of-type(3) {
    background: #cd454d;
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }

  &:nth-of-type(4) {
    background: #33cfde;
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }

  &:nth-of-type(5) {
    background: #5dde33;
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }
}