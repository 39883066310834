.inner-page-block {
  .clearfix();
  margin: 40px 0 0 0;
  padding: 0 0 20px 0;
  border-bottom: 1px solid #ddd;

  .block-header,
  h3{
    .muted{
      font-style: italic;
      font-size: 16px;
      font-weight: normal;
    }
  }
}

.checkout-alt {
  float: left;
  width: 100%;
  padding: 0;
  margin-top: 0px;
  background: none;
  box-shadow: none;
  border: none;
  .box-sizing(border-box);

  .block-header {
    font-size: 18px;
    margin: 0 -30px 1.2em;
    padding: 8px 30px 10px;
    font-weight: normal;
    border-bottom: 1px solid @grayBorder;

    .thin {
      font-weight: normal;
    }

    .note {
      display: inline-block;
      color: @grayLight;
      font-size: 11px;
      font-weight: normal;
      vertical-align: middle;
      white-space: nowrap;
    }

    .round-num {
      display: inline-block;
      width: 23px;
      height: 23px;
      margin: -3px 4px 0 0;
      line-height: 23px;
      text-align: center;
      .bold();
      color: @white;
      font-size: 13px;
      vertical-align: middle;
      background: @blue;
      border-radius: 10em;
    }
  }
}

.inner-page-block {
  padding: 5px 30px 20px;
  margin-top: 8px;
  background: @white;
  border: 1px solid @grayBorder;
}

.summary-section-total{
  .clearfix();
  float: right;
  text-align: center;
  padding: 4px 0;

  .sum-section-item{
    display: inline-block;
    width: 163px;
    padding: 8px 3px;
    margin-left: -1px;
    border-left: 1px solid @grayBorder;

    &:first-child{
      border-left: 0;
    }
  }

  .final-sum{
    font-size: 24px;
    line-height: 36px;
  }
}

.checkout-summary {
  width: 100%;
  margin-top: -10px;

  tbody{
    &:first-child{
      tr:first-child{
        td{
          border-top: none;
        }
      }
    }

    tr:first-child{
      td{
        border-top: 1px solid @grayBorder;
        padding-top: 7px;
      }
    }
    tr:last-child{
      td{
        padding-bottom: 7px;
      }
    }
  }

  td {
    vertical-align: top;

    &.value {
      width: 65px;
      text-align: right;
      white-space: nowrap;
    }
  }

  .details {
    font-size: 10px;
    margin: 2px 0 0 53px;
  }

  .description {
    text-align: right;
    color: @grayLight;
    font-size: 11px;
    margin: 0;

    & + .description {
      margin-top: 14px;
    }

    .tooltip {
      text-align: center;
    }

    &.left {
      text-align: left;
    }
  }
}

.hotel-info-wrap{
  .thumb{
    float: left;
    margin-right: 10px;
  }

  h4.title{
    font-size: 13px;
    margin: 0;
  }

  .rating-wrapper{
    float: left;
  }

  .address{
    font-size: 11px;
    color: @grayLight;
  }

  .map-view-wrapper{
    margin: 17px 0px 0px;
  }
}

.map-view-wrapper{
  position: relative;
  height: 162px;
  width: 267px;
  border: 1px solid #D2D2D2;

  .control{
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 3px 10px;
    .bold();
    background: white;
    background: rgba(255,255,255,0.7);
  }
}

.confirm-info{
  .info-sidebar{
    float: left;
    width: 335px;
  }

  .address{
    color: @grayDark;
  }
}

.info-sidebar{
  overflow: hidden;

  .preference-tag{
    display: inline-block;
  }
}

.grid-details {
  .left-col {
    float: left;
    width: 615px;
  }

  .right-col {
    float: right;
    width: 255px;
  }

  .list-header {
    margin: 0;
  }

  .list-header__rate-label {
    float: left;

    & + .list-header__rate-lnk {
      float: right;
    }

    .room-info-icon {
      top: 0;
      padding: 1px 3px;
      width: auto;
      height: auto;
      font-weight: bold;
      font-size: 11px;
      border-radius: 2px;
      background: lighten(@gray-dark, 8%);
    }
  }

  .confirm-status {
    line-height: 23px;
    padding: 6px 13px 9px 13px;
    margin: -5px -30px 0;
    font-size: 19px;
    font-weight: 200;
    color: @white;
    background: #4ab728;
    .box-sizing(border-box);

    &.canceled{
      background: #E13625;
    }
    &.pending{
      background: #2790f1;
    }

    [class*="icon-"]{
      position: relative;
      top: 5px;
    }
  }

  .inline-details {
    margin: 18px 0 0 0;

    .item {
      float: left;
      margin-right: 25px;
      white-space: nowrap;
    }
  }

  .status-footer {
    margin-top: 10px;
    padding-top: 18px;
    font-size: 18px;
    border-top: 1px solid @grayBorder;
  }

  article.top-level {
    padding: 8px 0 7px;
    border-top: 1px solid @grayBorder;

    .list-header {
      float: left;
      position: relative;
      font-weight: normal;
      font-size: 13px;
      color: @gray;
      width: 190px;
      margin: -8px 0 -7px 0;
      padding: 8px 10px 7px 10px;
      background: #fafafa;
      z-index: 1;
    }

    .columns-list {
      overflow: hidden;
      max-height: 1000px;
      .transition(max-height linear 0.5s);

      &.collapsed {
        max-height: 79px;
      }
    }

    .col-list {
      float: left;
      width: 260px;
      margin: 0 3px 0 15px;
    }

    .desc {
      position: relative;
      margin: -8px 0 -7px 190px;
      padding: 8px 0 7px 10px;
      background: @white;

      &:after{
        content: "";
        display: block;
        position: absolute;
        left: -190px;
        top: 0;
        width: 190px;
        height: 100%;
        background: #fafafa;
        z-index: 0;
      }

      ul{
        margin: 0 3px 3px 13px;
      }

      p{
        margin: 0 3px 2px 0;
      }
    }

    &.top-level:nth-of-type(1){
      padding-top: 8px;
      border-top: 1px solid #d2d2d2;
    }

    &.aft .desc {
      margin-left: 110px;
    }

    .reveal-control {
      clear: both;
      margin-left: 100px;
      padding: 2px 0 1px;
      font-size: 12px;
    }
  }

  .confirm-info {
    margin: 0 0 20px 0;

    .reservation-control {
      float: right;
      width: 190px;
      text-align: right;
    }
  }

  .sub-head {
    margin: 7px 0 0;
    font-size: 13px;
  }
}

.item__confirm-actions {
  a {
  float: left;
  display: block;
  margin: 3px 0 0 3px;
  padding: 1px 12px;
  color: @white;
  font-size: 11px;
  white-space: nowrap;
  background: rgba(0,0,0,0.3);
  border-radius: 2px;

    &:hover {
      background: rgba(0,0,0,0.4);
    }
  }

  i {
    top: -1px !important;
    margin-right: 4px;
  }
}

.secondary-list {
  margin: 10px 0;
  padding: 0;
  list-style: none;

  li {
    margin-bottom: 3px;
  }

  .title {
    color: @gray;
  }
}

.icon-checkmark-circle {
  width: 23px;
  height: 23px;
  background: url(../img/sprite_main.png) -40px 0 no-repeat;
}

.icon-minus-circle {
  width: 23px;
  height: 23px;
  background: url(../img/sprite_main.png) -40px -25px no-repeat;
}

.icon-download {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  background: url(../img/sprite_main.png) 0 0 no-repeat;
}

.icon-print {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  background: url(../img/sprite_main.png) -20px 0 no-repeat;
}

.rating-common{
  display: inline-block;
  height: 13px;
  margin: 2px 5px 0 0;

  span{
    position: relative;
    display: inline-block;
    float: left;
    width: 100%;
    height: 13px;
    background: url(../img/sprite_main.png) -70px 0px no-repeat;

    &.rating0{
      width: 0px;
    }
    &.rating0h{
      width: 6px;
    }
    &.rating1{
      width: 16px;
    }
    &.rating1h{
      width: 22px;
    }
    &.rating2{
      width: 32px;
    }
    &.rating2h{
      width: 38px;
    }
    &.rating3{
      width: 48px;
    }
    &.rating3h{
      width: 54px;
    }
    &.rating4{
      width: 64px;
    }
    &.rating4h{
      width: 70px;
    }
    &.rating5{
      width: 77px;
    }

    &.rating0h,
    &.rating1h,
    &.rating2h,
    &.rating3h,
    &.rating4h{
      margin-right: 6px;

      &:after{
        content: "";
        position: absolute;
        top: 0;
        right: -6px;
        width: 6px;
        height: 13px;
        background: url(../img/sprite_hotels.png) -26px -20px no-repeat;
      }
    }
  }
}


/* Cars & Hotels Confirmation */

.c-confirm__alerts {
  margin-top: 5px;
}

.c-confirm__header {
  margin-top: 15px;
}

.c-confirm__header__pnr-link {
  color: #008DF2;
}

.c-confirm__header__title {
  position: relative;
  padding: 8px 10px 8px 53px;
  font-size: 18px;
  color: @white;
}

.c-confirm__header__control {
  float: right;
  margin-top: -1px;

  .btn {
    float: left;
    margin-left: 10px;
    padding: 4px 10px;
    font-size: 11px;
    color: @white;
    background: rgba(255,255,255,0.28);
    border-radius: 3px;

    &:hover {
      background: rgba(255,255,255,0.2);
    }

    .glyphicons {
      margin-right: 4px;
    }
  }
}

.c-confirm__header__title--success {
  background: #14b734;

  &:before {
    .glyphicons();
    content: "\E194";

    position: absolute;
    top: 12px;
    left: 25px;
  }
}

.c-confirm__header__title--danger {
  background: @red;

  &:before {
    .glyphicons();
    content: "\E193";

    position: absolute;
    top: 12px;
    left: 25px;
  }
}

.c-confirm__header__title--warning {
  background: darken(@orange, 4%);

  &:before {
    .glyphicons();
    content: "\E505";

    position: absolute;
    top: 12px;
    left: 25px;
  }
}

.c-confirm__header__title--pending {
  background: darken(@orange, 2.5%);

  &:before {
    .glyphicons();
    content: "\E194";

    position: absolute;
    top: 12px;
    left: 25px;
  }
}

.c-confirm__test-alert {
  padding-left: 27px;
  position: relative;
  &:before {
    .glyphicons();
    position: absolute;
    top: 13px;
    left: 8px;
    content: "\e242"
  }
}

.c-confirm__header__content {
  .clearfix();
  padding: 25px 20px 25px 25px;
  font-size: 14px;
  background: @white;
  border: 1px dashed @gray-border;
  border-top: none;
}

.c-confirm__header__content__aside {
  float: left;
  width: 100%;
  margin: 20px 0 -25px 0;
  padding: 10px 0 11px;
  color: @gray;
  font-size: 11px;
  border-top: 1px dashed @gray-border;
}

.c-confirm__header__content__col {
  float: left;
  margin-right: 40px;
}

.c-confirm__header__content__col--right {
  float: right;
  margin-right: 0;
}

.c-confirm__section {
  .clearfix();
  margin-top: 20px;
  padding: 15px 30px;
}

.c-confirm__section__header {
  margin: 35px 0 20px;
  padding-bottom: 15px;
  font-size: 20px;
  border-bottom: 1px solid @gray-border;

  .btn-link {
    margin-top: -8px;
    font-size: 14px;
    background: rgba(255,255,255,0.8);
    border-radius: 4px;

    &.highlight-red {
      border: 1px solid @red;

      &:hover {
        color: @white;
        background: @red;
      }

      .btn__spinner {
        border-color: @red;
        border-right-color: transparent;
      }
    }
  }
}

.c-confirm__block {
  .clearfix();

  padding: 20px 25px;
  margin-bottom: 18px;
  background: @white;

  .c-content__item__icons {
    margin-top: 30px;
  }

  .c-content__item__car {
    height: auto;
  }

  .c-content__item__car__model {
    width: auto;
    max-width: 88%;
    margin: 0;
  }

  .c-content__item__features {
    float: left;
    position: static;
    width: 100%;
    margin: 10px 0 0;
  }

  .acriss-code {
    top: -7px;
    margin-left: 7px;
  }

  .col-xs-12 .c-confirm__table th {
    width: 24%;
  }
}

.c-confirm__block__header {
  margin: -20px -25px 20px;
  padding: 10px 25px;
  font-size: 16px;
  background: @gray-bg;
  border-bottom: 1px dashed @gray-border;
}

.c-confirm__block__inner-header {
  margin: 30px 0 14px;
  padding-bottom: 6px;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 1px solid @gray-border;

  &:first-child {
    margin-top: 0;
  }
}

.c-confirm__table__total {
  display: block;
  margin-top: 2px;
}

.c-confirm__block__note {
  margin: 15px 0 10px;
  font-size: 11px;
  color: @gray;
}

.c-confirm__table {
  font-size: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  th,
  td {
    padding: 7px 12px !important;
  }

  tr:last-child {
    th,
    td {
      border-bottom: 1px solid @table-border-color;
    }
  }

  th {
    width: 35%;
    background: @gray-bg;
  }

  .hotel-rate__source {
    padding: 4px 5px;
    font-size: 12px;
    border-radius: 0;
  }

  .glyphicons-airplane,
  .glyphicons-map-pin {
    float: right;
  }
}

.c-confirm__extra {
  margin: 7px 0 5px;
  font-size: 11px;
  color: @gray;
}

.c-confirm__extra__col {
  display: inline-block;
  margin-right: 10px;
}

.c-confirm__block__total {
  margin: 25px -25px -20px -25px;
  padding: 15px 25px;
  text-align: right;
  border-top: 1px dashed @gray-border;
}

.c-confirm__block__total__title {
  text-transform: uppercase;
}

.c-confirm__block__total__value {
  line-height: 1.4;
  font-size: 25px;
  font-weight: bold;
}

.c-confirm__hotel-details {
  .clearfix();
  margin: 0 0 30px;
}

.c-confirm__row {
  .clearfix();
  margin: 8px 0;
}

.c-confirm__thumb {
  float: left;
  width: 90px;
  height: 90px;
  background-size: cover;
}

.c-confirm__hotel {
  float: left;
  width: 82%;
  margin-left: 15px;

  h3 {
    margin: -3px 0 3px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.3;
  }
}

.h-item__hotel-name__label {
  margin-left: 3px;
  padding: 1px 6px 2px;
  line-height: 1;
  color: darken(@blue, 35%);
  white-space: nowrap;
  font-weight: normal;
  background: lighten(@orange, 10%);
  border-radius: 10em;
}

.c-confirm__addr {
  margin-top: 5px;
  font-size: 12px;
  color: @gray;
}

.c-confirm__addr__map-lnk {
  margin: 0 0 0 10px;
  font-size: 11px;
}

.c-confirm__info-col {
  float: left;
  margin-right: 9px;

  .hotel-card__rating-row {
    margin: 1px 0 0 !important;
  }
}

.c-confirm__footer-total {
  .clearfix();

  position: relative;
  margin: 17px 0 2px;
  border-top: 1px solid @gray-border;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 4px;
    bottom: 4px;
    width: 1px;
    border-left: 1px dotted #d4d5d6;
  }
}

.c-confirm__footer-total__col {
  float: left;
  width: 50%;
  padding: 14px 10px;
  text-align: center;
  font-size: 12px;
}

.c-confirm__footer-total__value {
  margin-top: 3px;
  font-size: 19px;
  font-weight: bold;
  color: #232629;
}

.c-confirm__right-col-wrapper {
  float: right;
  width: 88%;

  h4 {
    margin: 6px 0 30px;
    font-size: 15px;
    font-weight: bold;
  }

  .list-unstyled {
    li {
      margin-bottom: 7px;
    }
  }
}

.c-confirm__attn-btn {
  float: right;
  display: block;
  position: relative;
  width: 100%;
  margin: 10px 0 0;
  padding: 16px 23px 16px 14px;
  text-align: left;
  white-space: normal;
  border-radius: 10px;
  border: none;
  background: #183152;
  box-shadow: 0 12px 30px -12px rgba(30,86,158,0.8);

  &:hover {
    background: lighten(#183152, 7%);

    .c-confirm__attn-btn__title {
      color: @white;
    }
  }

  &:active,
  &:focus {
    background: #183152;
  }

  .glyphicon {
    position: absolute;
    right: 8px;
    top: 50%;
    margin-top: -7px;
    font-size: 14px;
    color: #E1E3E6;
  }
}

.c-confirm__attn-btn__title {
  margin: 0 0 3px !important;
  color: #E1E3E6;
  font-size: 15px;
  font-weight: bold;
}
