.ta-rating {
  position: relative;
  width: 120px;
  height: 20px;

  &:before {
    content: "";
    float: left;
    display: block;
    width: 32px;
    height: 20px;
    background-image: url(../img/ta-sprite.png);
    background-size: 90px 265px;
    background-repeat: no-repeat;
    background-position: 0 -1px;
  }

  &:after {
    content: "";
    float: left;
    display: block;
    width: 83px;
    height: 20px;
    margin-left: 4px;
    background-image: url(../img/ta-sprite.png);
    background-size: 90px 265px;
    background-repeat: no-repeat;
    background-position: -1px -223px;
  }
}

.ta-rating__title {
  margin: 0 0 5px;
  font-size: 10px;
  text-align: right;
}

.ta-rating--0:after {
  background-position: -1px -223px;
}

.ta-rating--05:after {
  background-position: -1px -203px;
}

.ta-rating--10:after {
  background-position: -1px -183px;
}

.ta-rating--15:after {
  background-position: -1px -163px;
}

.ta-rating--20:after {
  background-position: -1px -143px;
}

.ta-rating--25:after {
  background-position: -1px -123px;
}

.ta-rating--30:after {
  background-position: -1px -103px;
}

.ta-rating--35:after {
  background-position: -1px -83px;
}

.ta-rating--40:after {
  background-position: -1px -63px;
}

.ta-rating--45:after {
  background-position: -1px -43px;
}

.ta-rating--50:after {
  background-position: -1px -23px;
}

.ta-rating--disabled {
  &:before {
    background-position: -52px -1px;
  }

  &:after {
    background-position: -1px -243px;
  }
}