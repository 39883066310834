.a-content {
  float: right;
  width: 76%;
  padding: 25px 0 20px;

  .c-content__inline-a {
    margin: 15px 0;
  }
}

.a-head-panel {
  margin-top: -18px;
  padding: 5px 0;
  background: #3d4557;

  .container {
    display: flex;
  }
}

.a-head-panel__col {
  position: relative;
  margin-right: 3px;
  padding: 5px 10px 5px 12px;
  background: #f1f2f3;
  border-radius: 3px;

  &:first-child:after {
    .glyphicons();
    .rotate(90deg);

    content: "\2708";
    position: absolute;
    right: -11px;
    top: 50%;
    margin-top: -10px;
    padding: 4px;
    font-size: 11px;
    color: #90949e;
    background: #f1f2f3;
    border: 1px solid @gray-border;
    border-radius: 3px;
    z-index: 5;
  }
}

.a-head-panel__col--no-icon {
  &:after {
    display: none !important;
  }
}

.a-head-panel__col--destination {
  flex: 1;
  width: 28.6%;

  & + & {
    padding-left: 16px;
  }
}

.a-head-panel__col--date,
.a-head-panel__col--details {
  width: 17.7%;
}

.a-head-panel__col--details {
  padding-top: 6px;

  .a-head-panel__text {
    &:only-child {
      margin-top: 8px;
      font-size: 12px !important;

      .glyphicons {
        .rotate(0)
      }
    }
  }

  .glyphicons {
    margin-right: 9px;
    color: lighten(@gray, 3%);
  }
}

.a-head-panel__col--date {
  position: relative;
  padding: 14px 5px 0 35px;

  &:before {
    .glyphicons();
    content: "\e046";
    position: absolute;
    top: 14px;
    left: 9px;
    font-size: 16px;
    color: lighten(@gray, 5%);
  }
}

.a-head-panel__col__departure-icon {
  .rotate(90deg);
}

.a-head-panel__col__return-icon {
  .rotate(-90deg);
}

.a-head-panel__text {
  margin-bottom: 2px;
}

.a-head-panel__sub-text {
  .text-overflow();

  font-size: 11px;
  color: @gray;
}

.a-head-panel__btn {
  padding: 10px 0 10px 15px;
  color: @orange;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold !important;

  &:hover {
    color: #ff6100;
  }

  .glyphicons {
    float: left;
    font-size: 13px;
    margin-right: 5px;
  }
}

.head-panel__form--air {
  .head-panel__form-wrapper {
    width: 682px;

    .ag-home-form {
      padding: 28px 50px 15px;
    }
  }
}

.a-content__bar {
  .clearfix();
  position: relative;
  margin-bottom: 15px;
  padding: 7px 10px 4px 12px;
  color: #4a3512;
  font-size: 12px;
  background: #fff6e2;

  .tag-list {
    margin: 4px 0;
    padding-top: 3px;
    border-top: 1px dashed #efe4cd;
  }

  .tag-list__title {
    color: inherit;
  }

  .tag-list__item {
    line-height: 16px;
    background: rgba(255,255,255,0.3);
    border: 1px dotted #deceaa;

    .glyphicon-remove {
      margin-right: -1px;
      margin-left: 5px;
    }
  }
}

.a-content__total {
  float: left;
  margin-top: 3px;
  font-size: 14px;

  .btn {
    margin-left: 8px;
  }
}

.a-content__total__num {
  font-size: 15px;
}

.a-content__bar__header {
  margin: -2px 0 0;

  .btn-link {
    padding: 0;
    line-height: inherit;
  }
}

.a-content__item {
  position: relative;
  margin: 10px 0;
  border: 1px solid #caccce;
  border-radius: 5px;

  &:hover {
    box-shadow: 0 0 10px rgba(0,0,0,0.14);
  }

  & + & {
    margin-top: 25px;
  }
}

.a-content__item__hover {
  margin: -5px 0;
}

.a-content__item__wrapper {
  background: @white;
}

.a-content__item--empty {
  margin: 60px 0 30px;
  text-align: center;

  &:before {
    display: none;
  }

  h3 {
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 0.22em;
  }

  p {
    font-size: 14px;
    color: @gray-dark;
  }
}

.a-content__item__info-bar {
  display: table;
  width: 100%;
  padding: 4px 5px 4px 8px;
  background: lighten(#f9fafa, 1%);
  border-top: 1px dotted @gray-border;
  border-bottom: 1px dotted @gray-border;

  .a-provider-code { // Temp until FE of DES-485 implementation
    display: none;
  }
}

.a-content__item__header {
  .clearfix();

  display: table;
  position: relative;
  width: 100%;
  padding: 10px 5px 10px 8px;
  background: #f9fafa;
  border-radius: 5px 5px 0 0;
}

.a-content__item__col {
  display: table-cell;
  padding-right: 5px;
  vertical-align: middle;
  min-height: 1px;
}

.a-content__item__label {
  display: inline-block;
  margin: 3px 12px 0 0;
  padding: 3px 5px;
  font-size: 11px;
  font-weight: bold;
  line-height: 1;
  cursor: default;

  .glyphicons {
    margin-left: 4px;
    font-size: 9px;
  }
}

.a-content__item__label--danger {
  color: @white;
  background: @red;
  border-radius: 3px;
}

.a-content__item__pref-label {
  font-size: 11px;
  color: @green;
}

.a-content__item__col--price {
  width: 17%;
  padding-left: 5px;
}

.a-content__item__col--code {
  width: 30.5%;

  .a-flight-code__item { // Temp until FE of DES-485 implementation
    display: none;
  }

  .a-provider-code {
    position: relative;
    margin: 0 42px 0 0;

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: -35px;
      top: 50%;
      width: 25px;
      border-bottom: 1px solid @gray-dark;
    }
  }
}

.a-content__item__col--details {
  width: 33%;
}

.a-content__item__col--control {
  padding-right: 2px;
  text-align: right;

  .btn {
    margin: -1px 0;
    padding: 1px 20px;
    line-height: 32px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    background-color: @blue;
    border-color: @blue;

    &:hover {
      background-color: darken(@blue, 7%);
      border-color: darken(@blue, 7%);
    }
  }
}

.a-content__item__col--checkbox {
  width: 7%;
  border-bottom: transparent !important;
  transition: transform 0.2s;

  .checkbox__icon {
    position: absolute;

    &:before {
      top: -1px;
      width: 20px;
      height: 20px;
      border: 2px solid @gray-border;
      border-radius: 3px;
    }
  }

  input[type="checkbox"]:checked + .checkbox__icon {
    &:before {
      background: @blue;
      border-color: @blue;
    }

    &:after {
      top: 4px;
      left: -17px;
      font-size: 10px;
      color: @white;
    }
  }
}

.a-content__item__col--carrier {
  width: 8.4%;
  padding-right: 1.4% !important;
}

.a-content__item__col--route {
  width: 16%;
}

.a-content__item__col--primary-details {
  width: 23.9%;
  padding-left: 4.4% !important;
  line-height: 1.6;
}

.a-content__item__col--secondary-details {
  width: 19.2%;
  padding: 5px 0 !important;
  line-height: 1.8;
  text-transform: capitalize;

  .glyphicons,
  .glyphicon {
    margin-right: 8px;
  }

  .glyphicons-circle-info {
    margin-left: 3px;
    font-size: 10px;
    color: @blue;
  }

  .a-content__item__label--danger {
    margin: 0 0 8px;

  .glyphicons-circle-info {
      margin: 0 2px 0 4px;
      color: @white;
    }
  }
}

.a-content__item__col--pin-control {
  padding-left: 1% !important;
}

.a-content__item__col--details-control {
  position: relative;
  width: 3%;
}

.a-content__item__price-currency {
  margin-right: 5px;
  font-size: 12px;
  color: #444;
}

.a-content__item__price-value {
  font-size: 19px;
}

.a-content__item__emissions {
  font-size: 11px;

  &:before {
    content: "";
    display: inline-block;
    position: relative;
    top: 2px;
    left: -1px;
    width: 14px;
    height: 13px;
    margin-right: 5px;
    opacity: 0.9;
    background-size: cover;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgY2xpcC1wYXRoPSJ1cmwoI2EpIj48cGF0aCBkPSJNNi4wNSA4LjA1YTcuMDAxIDcuMDAxIDAgMCAwLS4wMiA5Ljg4YzEuNDctMy40IDQuMDktNi4yNCA3LjM2LTcuOTNBMTUuOTUyIDE1Ljk1MiAwIDAgMCA4IDE5LjMyYzIuNiAxLjIzIDUuOC43OCA3Ljk1LTEuMzdDMTkuNDMgMTQuNDcgMjAgNCAyMCA0UzkuNTMgNC41NyA2LjA1IDguMDV6IiBmaWxsPSIjMTkxOTFBIi8+PC9nPjxkZWZzPjxjbGlwUGF0aCBpZD0iYSI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTAgMGgyNHYyNEgweiIvPjwvY2xpcFBhdGg+PC9kZWZzPjwvc3ZnPg==")
  }
}

.a-provider-code {
  display: inline-block;
  min-width: 30px;
  padding: 2px 4px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: @white;
  background: @gray-dark;
}

.a-flight-code {
  display: inline-block;
  font-size: 12px !important;
}

.a-flight-code__item {
  margin-right: 7px;
  color: @gray;

  strong {
    color: @gray-darker;
  }

  .glyphicons {
    margin-right: 1px;
    font-size: 10px;
  }
}

.a-flight-code__type {
  padding: 2px 4px;
  font-size: 11px;
  background: darken(@gray-bg, 8%);
}

.a-flight-code__type--green-text {
  color: @green !important;
  background: #e3f3dd;
}

.a-flight-code__type--blue {
  color: @white !important;
  background: #041e5b;
}


.a-content__item__rate-details {
  display: block;
  position: relative;
  min-height: 12px;
  padding-left: 16px;
  line-height: 1;
  font-size: 12px;
  vertical-align: middle;

  & + & {
    margin-top: 8px;
  }

  &.highlight-green {
    &:before {
      .glyphicon();
      content: "\e013";
      position: absolute;
      top: 0;
      left: 0;
      margin-right: 7px;
      font-size: 8px;
    }
  }

  &.highlight-gray {
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 2px;
      left: -1px;
      width: 7px;
      height: 7px;
      margin: 0 9px 0 2px;
      border: 1px solid @gray-dark;
      border-radius: 100em;
    }
  }

  &.highlight-red {
    &:before {
      content: "";
      float: left;
      display: block;
      position: absolute;
      top: 3px;
      left: 0;
      width: 5px;
      height: 5px;
      margin: 0 10px 0 2px;
      background: @red;
      border-radius: 100em;
    }
  }

  .glyphicons {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 4px 0 -1px;
    color: @gray;
  }

  .glyphicons-chat {
    position: relative;
    top: 1px;
    margin: -2px 0 0 4px;
  }

  .btn-link {
    margin-top: -2px;
    padding: 0;
    font-size: 11px;
  }

  .loading-spinner {
    position: relative;
    top: -2px;
    width: 14px;
    height: 14px;
    margin: 1px 0 1px 3px;
    border-width: 2px;
  }
}

.a-content__item__section__title {
  display: inline-block;
  margin: 12px 0 0px 15px;
  padding: 2px 0;
  font-size: 12px;
  font-weight: bold;
}

.a-content__item__section {
  .clearfix();

  position: relative;
  display: table;
  width: 99.3%;
  padding: 0 0 0 3%;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    background: #f9fafb;

    .a-content__item__col--checkbox {
      .checkbox__icon {
        &:before {
          border-color: @blue;
          transition: border-color 0.2s;
        }
      }
    }

    .a-content__item__btn-details {
      background: #eaeff0;
      border: 1px solid rgba(255,255,255,0.7);

      &:hover {
        background: darken(#eaeff0, 4%);
      }
    }
  }

  .a-content__item__col {
    padding: 14px 0;
  }
}

.a-content__item__section--active {
  .a-content__item__btn-details {
    .glyphicons {
      .rotate(-90deg);
    }
  }
}

.a-content__item__section--selected {
  background: #fff7cf !important;
}

.a-content__item__section--empty {
  padding: 15px 5px 10px;
  text-align: center;
  font-style: italic;
  color: @gray-dark;
  background: inherit !important;
  cursor: default;

  strong:not(.btn-link) {
    color: @gray-darker;
  }

  &:last-child {
    border-bottom: 1px dotted @gray-border;
  }
}

.a-content__item__section__overlay {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: @red;
  -webkit-backdrop-filter: blur(2px) grayscale(70%) contrast(0.9);
  backdrop-filter: blur(2px) grayscale(70%) contrast(0.9);
  background: rgba(255,255,255,0.6);
  z-index: 44;
}

.a-content__item__section__header {
  padding: 5px 10px;
  font-size: 11px;
  text-align: right;
  color: @orange;
}

.a-content__item__section__control {
  .clearfix();

  padding: 6px 0 1px;

  & + .a-content__item__hr {
    margin-top: 12px;
  }
}

.a-content__item__carrier-logo {
  position: relative;
  width: 38px;
  height: 38px;
  border: 1px solid lighten(@gray-border, 7%);
  margin-top: 1px;
  background-color: @white;
  background-size: 30px;
  background-position: center;
  background-repeat: no-repeat;
}

.a-content__item__carrier-logo--mutiple {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 2px 5px -4px 0;
  background-size: 20px;
}

.a-content__item__datetime {
  .clearfix();

  margin: -2px 0 4px;
  white-space: nowrap;
}

.a-content__item__time {
  float: left;
  display: block;
  margin: 0 5px 1px 0;
  font-size: 18px;
  line-height: 1;
}

.a-content__item__date {
  position: relative;
  top: -1px;
  font-size: 12px;
  color: @gray;
  vertical-align: top;
}

.a-content__item__date__label {
  display: block;
  position: absolute;
  left: 100%;
  top: -3px;
  margin-left: 4px;
  font-size: 10px;
  color: @red;
  font-weight: bold;
  white-space: nowrap;
}

.a-content__item__city {
  display: inline-block;
  padding: 2px 4px;
  line-height: 1;
  font-size: 11px;
  font-weight: bold;
  background: #fff8e4;
  border-radius: 2px;
}

.a-content__item__city--destination {
  background: darken(@gray-bg, 3%);
}

.a-content__item__tag {
  position: relative;
  top: -4px;
  margin-left: 5px;
  padding: 1px 3px;
  font-size: 11px;
  line-height: 1;
  background: @white;
  border: 1px solid @gray-border;
  border-radius: 3px;

  .glyphicons {
    font-size: 10px;
  }
}

.a-content__item__tag--alt {
  border: 1px dashed lighten(@yellow, 5%);
  background: lighten(@yellow, 33%);
}

.a-content__item__btn-pin {
  position: relative;
  width: 26px;
  height: 26px;
  padding: 0;
  line-height: 1;
  color: @gray-border;
  background: @white;
  border: 1px solid @gray-border;
  border-radius: 20em;

  .glyphicons {
    position: absolute;
    font-size: 14px;
    top: -7px;
    left: 5px;
  }

  &:focus,
  &:active {
    color: @gray-border;
    background: @white;
    border-color: @gray-border;
  }

  &:hover {
    color: @blue;
    background: @white;
    border: 1px solid @blue;
  }
}

.a-content__item__btn-pin--pinned {
  color: @white;
  background: @red;
  border-color: @red;

  &:focus,
  &:active {
    color: @white;
    background: @red;
    border-color: @red;
  }

  &:hover {
    color: @red;
    background: @white;
    border-color: @red;
  }
}

.a-content__item__btn-pin--semi-pinned {
  color: #ff7e84;
  background: @white;
  border: 1px dashed @red;

  &:focus,
  &:active {
    color: #ff7e84;
    background: @white;
    border: 1px dashed @red;
  }

  &:hover {
    color: @red;
    background: @white;
    border: 1px solid @red;
  }
}

.a-content__item__btn-details {
  position: absolute;
  top: 5px;
  right: 0;
  bottom: 5px;
  width: 23px;
  margin-right: 3px;
  padding: 0;
  color: @gray-darker !important;
  border-radius: 3px;
  background: #f9fafa;

  .glyphicons {
    .rotate(90deg);

    font-size: 11px;
  }

  &:hover {
    background: darken(#f9fafa, 3%);
  }

  &:focus {
    background: @gray-bg;
  }
}

.a-content__item__hr {
  position: relative;
  margin: -1px 0 0;
  background: @white;
  border-top: 1px dashed @gray-border;
}

.a-content__details {
  .clearfix();
  margin: 14px 4% 22px 17.5%;
}

.a-content__details__header {
  margin: 0 0 16px;
  padding-bottom: 2px;
  font-size: 12px;
  border-bottom: 2px solid @red;
}

.a-content__details__header__text {
  font-weight: bold;
  text-transform: uppercase;
}

.a-content__details__aside {
  float: right;
  font-size: 11px;
}

.a-content__route-info {
  .a-content__item__carrier-logo {
    float: left;
    margin: 15px 3.5% 0 0;
  }
}

.a-content__route-info__time-table {
  float: left;
  width: 61%;
  margin: -7px 0 1px;
  font-size: 12px;

  tr {
    &:first-child {
      td {
        border-top: none !important;
      }
    }

    td {
      padding: 4px 5px !important;
      vertical-align: top;
      border-top: 1px dotted @gray-border !important;
    }
  }
}

.a-content__route-info__time-table__date {
  width: 29%;
}

.a-content__route-info__time-table__code {
  width: 11%;
}

.a-content__route-info__details {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  height: 79px;
  margin: 0 0 0 35px;
  font-size: 12px;

  > div:not(:first-child) {
    margin-top: 7px;
  }

  .glyphicons {
    margin-right: 4px;
  }
}

.a-content__route-info__pin {
  float: right;
  margin: 22px 13px 0 0;
}

.a-content__route-info__footer {
  float: left;
  width: 100%;
  margin: 9px 0 0;
  padding: 7px 0 0;
  font-size: 12px;
  color: @gray;
  border-top: 1px dashed @gray-border;
}

.a-content__route-info__footer__message {
  margin: 10px 0 0;
  padding: 7px 10px;
  color: @gray-darker;
  background: lighten(@yellow, 24%);

  .glyphicons {
    margin-left: 2px;
  }
}

.a-content__route-info__divider {
  float: left;
  width: 100%;
  margin: 12px 0 15px 0;
  padding: 4px 12px 4px 10px;
  font-size: 12px;
  background: darken(@gray-bg, 3%);
  border-radius: 10em;

  .glyphicons {
    top: 2px;
    margin-right: 8px;
    color: @gray;
  }
}
