.top-bar {
  .clearfix();
  margin: -18px 0 15px;
  background: #515152;
}

.top-bar__tabs {
  display: table;
  width: 100%;
  margin: 25px 0 0 0;
  padding: 0;
  list-style: none;
  table-layout: fixed;
}

.top-bar__message {
  margin: 10px 0 -5px;
  padding: 7px 15px;
  color: @white;
  line-height: 1.6;
  background: #444445;
  border: 1px solid #686869;
  border-radius: 2px;

  button {
    padding: 0px;
    color: #a7ccff;

    &:hover {
      color: darken(#a7ccff, 7%);
    }
  }
}

.top-bar__tabs__item {
  display: table-cell;
  margin: 0;
  vertical-align: bottom;
  cursor: default;

  &:hover:not(.top-bar__tabs__item--active):not(.top-bar__tabs__item--empty) {
    cursor: pointer;

    .top-bar__tabs__content {
      background: lighten(#e6e7e8, 4%);
    }

    .top-bar__tabs__header {
      background: darken(#888, 5%);
    }
  }
}

.top-bar__tabs__item--empty {
  .top-bar__tabs__wrapper {
    min-height: 44px;
    border: 1px dashed #686869;
    border-bottom: none;
    background: #4c4c4d;
  }
}

.top-bar__tabs__item--active {
  width: 180px;

  .top-bar__tabs__label {
    display: none;
  }

  .top-bar__tabs__header {
    background: lighten(@gray, 4%);
  }

  .top-bar__tabs__active-toggled {
    display: inline;
  }

  .top-bar__tabs__default-toggled,
  .top-bar__tabs__content__row {
    display: none;
  }

  .top-bar__tabs__content {
    background: #f1f2f4;
    border-bottom: none;

    .table {
      display: table;
    }
  }
}

.top-bar__tabs__wrapper {
  position: relative;
  margin-right: 5px;
}

.top-bar__tabs__label {
  position: absolute;
  bottom: 100%;
  right: 0;
  margin-bottom: 3px;
  padding: 2px 5px;
  line-height: 1;
  font-size: 11px;
  color: @white;
  text-align: right;
  background: #d98e43;
  border-radius: 2px;

  &:empty {
    display: none;
  }
}

.top-bar__tabs__item--danger {
  .top-bar__tabs__label,
  .top-bar__tabs__header {
    background: @red;
  }

  &:not(.top-bar__tabs__item--active):hover {
    .top-bar__tabs__label,
    .top-bar__tabs__header {
      background: darken(@red, 6%) !important;
    }
  }
}

.top-bar__tabs__item--success {
  .top-bar__tabs__label,
  .top-bar__tabs__header {
    background: @green;
  }

  &:not(.top-bar__tabs__item--active):hover {
    .top-bar__tabs__label,
    .top-bar__tabs__header {
      background: darken(@green, 5%) !important;
    }
  }
}

.top-bar__tabs__item--progress {
  .top-bar__tabs__label,
  .top-bar__tabs__header {
    background: lighten(@gray, 4%);
  }

  .top-bar__tabs__label {
    .fade-in-out();
  }
}

.top-bar__tabs__item--warning {
  .top-bar__tabs__label,
  .top-bar__tabs__header {
    background: darken(@orange, 9%);
  }

  &:not(.top-bar__tabs__item--active):hover {
    .top-bar__tabs__label,
    .top-bar__tabs__header {
      background: darken(@orange, 6%) !important;
    }
  }
}

.top-bar__tabs__item--alt-warning {
  .top-bar__tabs__header {
    color: darken(@orange, 21%);
    background: #f9d56e !important;
  }

  .top-bar__tabs__label {
    padding-right: 0;
    padding-left: 0;
    color: darken(@orange, 3%);
    font-weight: bold;
    background: transparent;
  }

  &:not(.top-bar__tabs__item--active):hover {
    .top-bar__tabs__header {
      color: darken(@orange, 21%);
      background: @yellow !important;
    }
  }
}

.top-bar__tabs__header {
  padding: 2px 4px 2px 7px;
  font-size: 11px;
  color: @white;
  background: #888;
}

.top-bar__tabs__content {
  padding: 4px 4px 4px 7px;
  font-size: 11px;
  background: #e6e7e8;
  border-bottom: 1px solid #b3b3b3;

  .table {
    display: none;
    margin: 1px 0;

    th,
    td {
      padding: 1px 2px 1px 0;
      border: none;
    }

    th {
      width: 40%;
    }
  }
}

.top-bar__tabs__content__row {
  .text-overflow();
}

.top-bar__tabs__active-toggled {
  display: none;
}
