.wrapp {
  height: 100vh;
}

.wallet-box {
  display: flex;
  padding-top: 60px;

  &__wrapper {
    flex: 0 1 1170px;
    margin: 0 auto;

    &_rounded {
      border-radius: 8px;
    }

    .caret {
      display: none;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    .caret {
      display: none;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 30px 10px 30px;
  }

  &__text {
    font-size: 18px;
  }

  &__pre-text {
    color: @grayDark;
    margin: 10px 0;
  }

  &__container {
    padding: 15px 0 50px 0;
  }

  &__footer {
    margin-bottom: 70px;
  }
}

.wallet-card {
  margin-left: -15px;

  &__line > td:first-child {
    font-weight: 700;
    color: @grayDarker;
    min-width: 140px;
    vertical-align: top;
  }

  &__table > tbody > tr > td {
    padding: 0 8px 10px 0;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    min-width: 220px;
  }

  &__item {
    color: @brand-primary;
    position: relative;

    span {
      margin-right: 4px;
    }
  }
}

.wallet-box {

  &__btn-block {
    margin: 30px 0 55px;
  }

  &__btn {
    background: @btn-default-color;
    border-radius: 3px;
    border-color: @btn-secondary-border;

    > a {
      color: @white;
    }

    & > a:hover, > a:focus {
      border-radius: 3px;
      color: @white;
      background: @btn-default-color !important;
    }

    & .dropdown-menu {
      width: 200px;
      margin: 6px 0 0 -47px;
    }
  }

  &__form {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }
}
