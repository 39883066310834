.lang-es {
  .h-item__tabs a {
    padding: 3px 12px;
  }
}

.lang-fr {
  .h-item__tabs a {
    padding: 3px 12px;
  }
}