@-webkit-keyframes fade-in {
  0% {
    .opacity(0)
  }
  100% {
    .opacity(1)
  }
}

@-moz-keyframes fade-in {
  0% {
    .opacity(0)
  }
  100% {
    .opacity(1)
  }
}

@keyframes fade-in {
  0% {
    .opacity(0)
  }
  100% {
    .opacity(1)
  }
}

.head-panel {
  .clearfix();
  position: relative;
  bottom: 18px;
  background: @white;
  border-bottom: 1px solid @gray-border;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 8px;
    width: 43px;
    height: 43px;
    margin-left: -36px;
    border: 1px solid @gray-border;
    border-left: none;
    border-bottom: none;
    .rotate(45deg);
  }
}

.head-panel__overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: -1px;
  right: 0;
  z-index: 10;
  background: rgba(255,255,255,0.7);
  cursor: pointer;

  .opacity(0);
  .transition(opacity 0.25s);

  &:hover {
    .opacity(1);
  }

  .head-panel__overlay__icon {
    margin: 10px auto 0;
    width: 40px;
    height: 40px;
    line-height: 42px;
    text-align: center;
    font-size: 18px;
    color: @blue;
    background: @white;
    border-radius: 20em;
    box-shadow: 0 0 17px rgba(10,20,130,0.2);

    .glyphicon {
      margin: 0;
    }
  }
}

.head-panel__form {
  display: none;
  position: absolute;
  top: 52px;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255,255,255,0.9);
  z-index: 45;

  .block-title {
    display: none;
  }

  .ag-home-form {
    margin: 0;
  }

  .ag-home-form__control--bottom {
    margin-top: 10px;
    padding-top: 17px;
  }

  .head-panel__form-wrapper {
    width: 750px;
    margin: 0 auto;
    background: #f7f7f7;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 20px 30px rgba(27,27,68,0.35);

    .ag-home-form {
      padding: 28px 40px 15px;
    }

    .form-group__active-field {
      margin-left: 190px;
    }
  }

  .main-form__footer {
    background: @white;
    border-top-color: #ebebeb;
  }

  label {
    color: #333;
  }

  .checkbox__icon:before {
    border-color: @gray-dark;
  }

  .row__toggle__label {
    margin: 0;
  }
}

.head-panel__form--in {
  display: block;
  .animation(fade-in 0.3s);
}

.head-panel__col {
  width: 46%;
  height: 60px;
}

.head-panel__location {
  float: left;
  width: 57%;
  padding-top: 11px;
}

.head-panel__location__header {
  margin: 0 0 3px;
  color: darken(@orange, 2%);
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
}

.head-panel__location__name {
  .text-overflow();
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: default;
}

.head-panel__details {
  float: right;
  position: relative;
  bottom: -1px;
  width: 43%;
  height: 60px;
  padding-top: 9px;
  border-bottom: 1px solid #333;
}

.head-panel__details--time-period {
  .head-panel__details__time {
    width: 80px;
    margin-top: 5px;
    font-size: 33px;
  }

  .head-panel__details__period {
    float: right;
    margin-top: 4px;
    font-size: 12px;
  }

  .head-panel__details__date {
    width: 46px;
    margin-top: 5px;
    font-size: 33px;
  }

  .head-panel__details__month {
    margin-top: 5px;
    font-size: 13px;
  }

  .head-panel__details__day {
    margin-top: 0;
  }
}

.head-panel__details__date {
  float: left;
  width: 51px;
  padding: 0 7px 0 0;
  font-size: 39px;
  letter-spacing: -0.03em;
  line-height: 1;
  text-align: right;
}

.head-panel__details__aside {
  float: left;
  width: 77px;
}

.head-panel__details__month {
  padding: 1px 0 0;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.head-panel__details__day {
  margin-top: 3px;
  color: #949dab;
  font-size: 11px;
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
}

.head-panel__details__time {
  float: left;
  width: 96px;
  font-size: 39px;
  letter-spacing: -0.03em;
  line-height: 1;
  text-align: right;
}

.c-content {
  float: right;
  width: 76%;
  padding: 26px 0 20px;
}

.c-content__bar {
  display: flex;
  justify-content: space-between;
  position: relative;
  min-height: 40px;
  padding: 7px 10px 7px 12px;
  color: #4a3512;
  font-size: 12px;
  background: #fff6e2;
}

.c-content__total {
  font-size: 15px;

  .btn {
    margin-left: 8px;
  }
}

.c-content__total__num {
  font-size: 16px;
}

.c-content__bar__filter {
  margin-top: 3px;

  [class^="col-xs"],
  [class*=" col-xs"] {
    padding: 0;
  }

  .control-label {
    padding: 6px 14px 0 0;
    font-size: 11px;
    font-weight: bold;
    text-align: right;
  }

  .custom-select {
    margin-right: 0;
  }

  .value {
    height: 29px;
    line-height: 29px;
    font-size: 11px;

    &:after {
      top: 13px;
    }
  }
}

.c-content__sort-bar {
  .clearfix();
  margin: 20px 0 26px;
  padding: 0 0 6px 10px;
  border-bottom: 1px solid @gray-border;
}

.c-content__sort-bar__col {
  float: left;
  min-height: 1px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  color: lighten(@gray, 5%);
  cursor: pointer;

  &:hover {
    color: @gray-darker;
  }
}

.c-content__sort-bar__col--disabled {
  pointer-events: none;
}

.c-content__sort-bar__col--sort-asc {
  .c-content__sort-bar__text:after {
    content: "";
    display: inline-block;
    position: relative;
    top: -2px;
    margin-left: 6px;
    border-top: 4px solid @red;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
}

.c-content__sort-bar__col--sort-desc {
  .c-content__sort-bar__text:after {
    content: "";
    display: inline-block;
    position: relative;
    top: -2px;
    margin-left: 6px;
    border-right: 4px solid transparent;
    border-bottom: 4px solid @red;
    border-left: 4px solid transparent;
  }
}

.c-content__sort-bar__col--price,
.c-content__item__col--price,
.c-content__item__footer-col--common {
  position: relative;
  width: 15%;
}

.c-content__sort-bar__col--info,
.c-content__item__col--info,
.c-content__item__footer-col--payments {
  width: 24.3%;
}

.c-content__sort-bar__col--location,
.c-content__item__col--location {
  .break-word();
  width: 23.4%;
  padding-left: 24px;
}

.c-content__item__footer-col--details {
  position: relative;
  width: 27%;

  .btn {
    margin-top: 1px;
  }
}

.c-content__item__col--control {
  float: right !important;
  width: 11.4%;

  .btn {
    float: right;
    margin-top: -7px;
    padding: 9px 10px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.c-content__item {
  .clearfix();
  position: relative;
  margin: 0 0 24px 0;
  padding: 0 0 0 10px;

  &:hover {
    .c-content__item__hover {
      display: block;
    }
  }

  .muted {
    color: #888;
  }
}

.c-content__item__hover {
  display: none;
  position: absolute;
  left: -16px;
  top: -5px;
  bottom: 0;
  z-index: 10;

  .btn {
    box-shadow: 0 0 12px 5px white;
  }
}

.c-content__item__col {
  float: left;
}

.c-content__item__price {
  font-size: 13px;

  .small {
    font-size: 11px;
  }
}

.c-content__item__price__sub {
  margin-top: 2px;
  font-size: 14px;

  .small {
    font-size: 12px;
  }
}

.c-content__item__price__label {
  display: inline-block;
  margin: 5px 15px 0 0;
  padding: 2px 4px;
  font-size: 11px;
  line-height: 15px;
  color: darken(@orange, 30%);
  background: lighten(@yellow, 14%);
  border-radius: 2px;

  .glyphicon {
    float: left;
    margin: 1px 4px 0 1px;
  }
}

.c-content__item__num {
  margin-top: 9px;
  font-size: 11px;
  color: @gray;

  & + & {
    margin-top: 1px;
  }
}

.c-content__item__img {
  height: 63px;
  margin: -17px 0 0;
  background-size: contain;
  background-repeat: no-repeat;

  & + .c-content__item__price {
    width: 94%;
    margin-top: -10px;
    padding: 4px 6px 0 2px;
    background: rgba(255,255,255,0.85);
    box-shadow: 0 -9px 11px -12px rgba(0,0,0,0.35);
  }
}

.c-content__item__no-img {
  height: 63px;
  margin: -17px 0 0;
  background: url(../img/no-image.png) 20px 0 no-repeat;
  background-size: contain;
  opacity: 0.8;
}

.c-content__item__car {
  height: 40px;
  margin: 2px 0 0;
  font-size: 14px;
  font-weight: bold;
}

.c-content__item__car__label {
  display: block;
  margin: -5px 0 1px 0;
  font-size: 11px;
  font-weight: normal;
  color: #555;
}

.c-content__item__car__aside {
  display: block;
  margin: 3px 0 0;
  font-weight: normal;
  font-size: 12px;
}

.c-content__item__car__model {
  float: left;
  display: block;
  width: 72%;
  margin: 0 0 6px;
  line-height: 1.3;
  cursor: default;
}

.c-content__item__destination {
  display: inline-block;
  margin-top: 3px;
  line-height: 1.4;
  font-size: 11px;
}

.c-content__item__col--location {
  position: relative;
  margin-top: 3px;
  font-size: 12px;
  line-height: 1.5;

  .glyphicons {
    position: absolute;
    left: 6px;
    top: 2px;
    color: lighten(@gray, 5%);
  }

  .glyphicons-map-pin {
    top: 1px;
    font-size: 13px;
  }
}

.c-content__item__footer {
  display: table;
  float: left;
  width: 100%;
  margin: 9px 0 0 0;
}

.c-content__item__footer-col {
  display: table-cell;
  padding: 10px 0 14px;
  vertical-align: middle;
  border-top: 1px dotted @gray-border;
  border-bottom: 1px solid lighten(@gray-border, 3%);
}

.c-content__item__footer-col--common {
  border-top: none;
  border-bottom-color: @gray-dark;
}

.c-content__item__footer-col--payments {
  font-size: 11px;
  text-align: right;
}

.c-content__item__heading {
  margin-bottom: 3px;
  font-size: 11px;
  font-weight: bold;
  color: #888;
  text-transform: uppercase;
}

.c-vendor {
  float: left;
  color: @gray;
  font-size: 11px;
  cursor: default;
}

.c-vendor__logo {
  display: inline-block;
  width: auto;
  height: 23px;
  margin-top: -1px;
  margin-left: 2px;
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
  background-size: contain;
  background-repeat: no-repeat;
}

.c-content__item__features {
  color: @green;
  margin-top: -1px;
  font-size: 11px;

  & + & {
    margin-top: 2px;
  }
}

.c-content__item__features__warning {
  color: @orange;
  margin-top: -1px;
  font-size: 11px;

  & + & {
    margin-top: 2px;
  }
}

.c-content__item__label {
  float: right;
  margin-top: 15px;
  padding: 1px 7px;
  font-weight: bold;
  background: #fff1f1;
  border-radius: 3px;
}

.c-pagination__wrapper {
  margin: 50px 0 25px;
  text-align: center;
}

.c-pagination {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
  .user-select(none);
}

.c-pagination__nav {
  float: left;
  min-width: 80px;
  margin: 4px 30px 0;
  padding: 6px 5px;
  font-size: 12px;
  line-height: 1;
  border: 1px solid @gray-border;
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    background: @gray-bg;
    border-color: darken(@gray-border, 8%);
  }
}

.c-pagination__nav--inactive {
  pointer-events: none;
  color: @gray-light;
  border-color: @gray-light;
}

.c-pagination__page {
  float: left;
  padding: 2px 9px 3px;
  font-size: 16px;
  border-bottom: 3px solid #f1f2f4;
  cursor: pointer;

  &:hover:not(.c-pagination__page--active) {
    border-bottom-color: @gray;
  }
}

.c-pagination__page--inactive {
  pointer-events: none;
}

.c-pagination__page--active {
  pointer-events: none;
  border-bottom-color: @red;
}

.acriss-code {
  display: inline-block;
  position: relative;
  top: -1px;
  margin-left: 3px;
  padding: 2px;
  line-height: 10px;
  font-size: 11px;
  font-weight: bold;
  border: 1px solid @gray-darker;
  cursor: default;
  border-radius: 2px;

  &:hover {
    color: @white;
    background: @gray-darker;
  }
}

.c-rate-ico__wrapper {
  float: left;

  .c-rate-ico {
    &:first-child:not(:only-child) {
      margin-right: 3px;

      &:after {
        display: none;
      }
    }
  }
}

.c-rate-ico {
  float: left;
  position: relative;
  width: 27px;
  height: 26px;
  margin: 3px 3px 0 0;
  line-height: 26px;
  font-size: 13px;
  color: darken(@gray, 8%);
  text-align: center;
  font-weight: bold;
  background: darken(@gray-bg, 2%);
  cursor: default;

  &:last-child {
    margin-right: 46px;

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: -35px;
      top: 13px;
      width: 21px;
      border-top: 1px solid @gray-darker;
    }
  }
}

.c-rate-ico--orange {
  color: @white;
  background: @orange;
}

.c-rate-ico--green {
  color: @white;
  background: @green;
}

.c-rate-ico--teal {
  color: @white;
  background: #47B1B8;
}
.c-rate-ico--wide-blue {
  width: 58px;
  font-size: 12px;
  font-weight: normal;
  color: @white;
  background: #4781d2;
}
