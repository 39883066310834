.dropdown-menu--rates-filter {
  width: 225px;
  border: none;
  box-shadow: 0 0 9px rgba(0,0,70,0.19);
  border-radius: 0;
}

.dropdown-menu__header {
  margin: -5px 0 2px;
  padding: 8px 14px;
  line-height: 1;
  font-size: 13px;
  font-weight: bold;
  background: @gray-bg;
}

.rate-filter__wrapper {
  padding: 6px 14px;
}

.rate-filter__item {
  margin-bottom: 5px;
  font-size: 12px;

  .control-label {
    margin: 7px 0 0;
    font-weight: bold;
  }

  .checkbox,
  .radio {
    margin-top: 7px;
    margin-bottom: 7px;
    padding-left: 23px;
  }
}

.rate-filter__footer {
  margin: 5px 0 0;
  padding: 12px 14px 6px;
  border-top: 1px solid @gray-border;

  .btn {
    margin-right: 5px;
  }
}