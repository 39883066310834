.a-page--price {
  .a-result-item__wrapper {
    box-shadow: none;
    cursor: auto;

    .a-content__item__emissions {
      margin-top: 3px;
      font-size: 12px;
    }
  }

  .a-result-item__carrier {
    width: 120px;
  }

  .a-result-item__details {
    width: 210px;
  }

  .a-result-item__time {
    width: 275px;
  }

  .a-result-item__control {
    flex: 1;
    padding-right: 10px;
    text-align: right;
  }

  .btn-link--details {
    width: 35px;
    height: 35px;
    margin-left: 30px;
    padding: 0;
    color: @gray-dark;
    border-radius: 50%;

    .a-result-item__details-icon {
      .glyphicons();
      .glyphicons-chevron-down();
      top: 2px;
    }

    &:hover {
      background: darken(@gray-bg, 3%);
    }
  }

  .a-result-item__wrapper--details {
    border-color: darken(@gray-border, 15%);

    .a-result-item__details-icon {
      .glyphicons-chevron-up();
    }

    .a-content__details {
      display: block;
    }
  }

  .a-content__details {
    display: none;
    margin: 30px 2% 15px 36.5%;
  }

  .a-content__route-info__time-table {
    width: 72%;
  }
}

.a-fare-item__list {
  padding-top: 20px;

  .alert {
    margin: 35px 0 60px;
    padding: 20px 10px;
    font-size: 15px;
    text-align: center;
  }
}

.a-fare-item {
  display: flex;
  position: relative;
  align-items: center;
  padding: 10px 15px 10px;
  border-bottom: 1px dotted @gray-border;

  &:hover {
    background: @gray-bg;
    border-bottom: 1px solid @gray-border;

    .c-content__item__hover {
      display: block;
      left: -18px;
      top: 8px;
    }
  }
}

.a-fare-item__overlay {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: @red;
  -webkit-backdrop-filter: blur(2px) grayscale(60%);
  backdrop-filter: blur(2px) grayscale(60%);
  background: rgba(255,255,255,0.7);
  z-index: 100;
}

.a-fare-item__empty {
  padding: 70px 20px;
  font-size: 18px;
  color: @gray;
  text-align: center;
  background: darken(@gray-bg, 2%);
}

.a-fare-item__price {
  width: 13%;
  font-size: 15px;
}

.a-fare-item__type {
  width: 12%;
}

.a-fare-item__family {
  width: 12%;
}

.a-fare-item__class {
  width: 13%;
}

.a-fare-item__code {
  width: 12%;
  font-family: monospace;
}

.a-fare-item__details {
  width: 20%;

  .glyphicons {
    margin-right: 5px;
  }
}

.a-fare-item__control {
  flex: 1;
  margin-left: 10px;
  text-align: right;
}

.a-table-selector {
  padding-top: 5px;
}

.a-table-selector__row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  padding: 0 0 0 90px;

  & + & {
    margin-top: 10px;
  }

  &:hover {
    background: @gray-bg;
  }
}

.a-table-selector__item {
  font-size: 12px;
  min-width: 40px;

  .radio {
    min-width: 55px;
    padding: 6px 0 6px 30px;
  }

  .radio__icon {
    top: -1px;
  }

  .radio-hidden {
    display: none;
  }

  &:not(.a-table-selector__item--segment):hover {
    transform: scale(1.2);
    background: @white;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    z-index: 5;
  }
}

.a-table-selector__item--segment {
  position: absolute;
  left: 0;
  top: 5px;
  max-width: 85px;
  font-weight: bold;
}

.a-table-selector__item--highlighted {
  background: #d7ffe0;
}

.a-input-selector {
  display: flex;
  flex-wrap: wrap;
}

.a-input-selector__item {
  flex-grow: 1;
  max-width: 33.3%;
  padding-right: 5px;
}

.a-rev-filter {
  padding: 50px 0 15px;
}

.a-rev-filter__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.a-rev-filter__section {
  margin: 30px 0 20px;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 0 18px rgba(0,0,0,0.18);
}

.a-rev-filter__footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
  padding: 35px 5px 15px;
  border-top: 1px dotted @gray-border;

  .checkbox {
    margin-left: 50px;
  }

  .btn {
    min-width: 220px;
    margin-left: 45px;
    padding: 15px 20px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 20em;
  }
}
