.checkout__info__item {
  margin: 10px 0;
  padding-top: 2px;
  background: @gray-bg;
  border-radius: 6px;
  border: 1px solid @gray-border;
}

.checkout__info__item__attn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
  padding: 0 15px;
  font-size: 12px;
}

.checkout__info__item__code {
  font-size: 14px;
  font-family: monospace;
}

.checkout__info__item__row {
  margin-top: 7px;
  padding: 7px 15px 0;
  text-align: right;
  font-size: 11px;
  border-top: 1px dotted @gray-border;
}

.checkout__info__item__footer {
  margin-top: 8px;
  padding: 7px 15px 5px;
  color: @gray-darker;
  text-align: right;
  line-height: 1.7;
  font-size: 12px;
  background: #fff;
  border-top: 1px dotted @gray-border;
  border-radius: 6px;

  .glyphicons {
    margin-right: 5px;
    font-size: 11px;
  }

  .glyphicons-airplane {
    display: none;
  }
}

.checkout__info__section__value {
  .a-flight-code__item {
    display: inline-block;
    margin: 0 0 0 6px;
  }
}

.checkout__info__section.ticket-title {
  font-size: 15px;
  padding: 9px 16px 0;

  hr {
    margin: 5px 0;
  }
}

.exchange__spinner {
  width: 12px;
  height: 12px;
  position: relative;
  top: 2px;
}
