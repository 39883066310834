.modal-body__cxl-info {
  padding: 0 20px 0 0;
}

.modal-body__cxl-info__confirmation {
  margin: 20px 0;
  padding: 10px 20px;
  background: @gray-bg;
  border: 1px dotted @gray-border;
  border-right: none;
  border-left: none;

  .checkbox label {
    font-weight: bold;
  }
}

.modal-body__cxl-info__control {
  .btn {
    width: 48%;
  }
}

.modal-body__checkout-info {
  padding: 10px 0 0;
  background: @gray-bg;
  border: 1px dashed @gray-border;

  .checkout__info__section,
  .checkout__info > hr {
    margin: 0;
  }

  .table {
    background: transparent;
  }

  .checkout__info__note {
    margin: 0 15px 11px;
  }
}