.ty-logo {
  width: 85px;
  height: 11px;
  background: url(../img/TY-logo-sm.png) 0 0 no-repeat;
  background-size: contain;
}

.ty-rating__title {
  margin: 0 0 3px;
  font-size: 10px;
  text-align: right;
}

.ty-eco {
  float: right;
  font-size: 11px;
  width: 22px;
  height: 22px;
  margin: 2px 10px 0 0;
  text-align: center;
  color: #7CB342;
  background: #EEF5E6;
  border-radius: 20em;

  .glyphicons {
    margin-top: 5px;
  }
}

.sidebar-item {
  .ty-eco {
    float: none;
    display: inline-block;
    margin: -2px 5px 0 -1px;
  }
}

.ty-rating {
  position: relative;
  float: right;
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
  padding: 2px 5px;
  color: @white;
  background: @gray;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    border-top: 5px solid @gray;
    border-right: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid @gray;
  }
}

.ty-rating--green {
  background: #7CB342;

  &:after {
    border-color: #7CB342 transparent transparent #7CB342;
  }
}

.ty-rating--yellow {
  background: #FFA726;

  &:after {
    border-color: #FFA726 transparent transparent #FFA726;
  }
}

.ty-rating--red {
  background: #f06748;

  &:after {
    border-color: #f06748 transparent transparent #f06748;
  }
}