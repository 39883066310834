.a-breadcrumb {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding: 10px 0 0 45px;
  border-top: 1px dotted @gray-border;
  border-bottom: 1px dotted @gray-border;
}

.a-breadcrumb__item {
  display: flex;
  position: relative;
  align-items: center;
  min-height: 51px;
  margin: 0 15px 9px 0;
  font-size: 14px;
  overflow: hidden;

  &:hover {
    .a-breadcrumb__item__overlay {
      opacity: 1;
      transition: opacity 0.4s;
    }

    .a-breadcrumb__item__overlay__text {
      transform: translateY(0);
      transition: transform 0.4s;
    }
  }

  .a-result-item__logo {
    width: 23px;
    height: 23px;
    margin: 0 auto;
    background-size: 23px;
  }
}

.a-breadcrumb__item__col--logo {
  margin-right: 10px;
  width: 37px;
}

.a-breadcrumb__time {
  margin: 0 0 1px;
  font-size: 13px;
}

.a-breadcrumb__details {
  font-size: 11px;
  color: @gray;
}

.a-breadcrumb__item__overlay {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  z-index: 5;
  background: rgba(255,255,255,0.7);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s;
}

.a-breadcrumb__item__overlay__text {
  display: inline-block;
  padding: 4px 7px;
  color: @blue;
  font-weight: bold;
  background: @white;
  border-radius: 3px;
  border: 1px solid @blue;
  transform: translateY(100%);
  transition: transform 0.2s;
}

.a-breadcrumb__item--home {
  margin: 0 9px 9px -45px;

  & + .a-breadcrumb__item--separator {
    margin-right: 11px
  }
}

.a-breadcrumb__item--separator {
  .glyphicons {
    font-size: 12px;
    top: 0;
    color: @gray;
  }

  .clickable:hover {
    color: @gray-darker;
  }
}

.a-breadcrumb__item--title {
  justify-content: center;
  background: @gray-bg;
}

.a-breadcrumb__item--selected,
.a-breadcrumb__item--disabled {
  width: 28.1%;
  padding: 7px 15px 7px 10px;
  border: 1px dashed @gray-border;
  border-radius: 4px;

  &:hover {
    border-color: darken(@gray-border, 10%);
  }
}

.a-breadcrumb__item--disabled {
  color: @gray;
  pointer-events: none;
  background: @gray-bg;
}

.a-result-item__title {
  margin-bottom: 12px;
}

.a-result-item__wrapper {
  position: relative;
  margin: 10px 0;
  padding: 9px 0 9px 20px;
  border: 1px solid @gray-border;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    border-color: darken(@gray-border, 10%);
    box-shadow: 0 0 18px rgba(0,0,0,0.12);

    .c-content__item__hover {
      display: block;
      top: 5px;
    }
  }
}

.a-result-item__wrapper--selected {
  background: #fff7cf !important;

  .a-result-item__seats {
    background: #fff7cf !important;
  }
}

.a-result-item__wrapper--muted {
  border-style: dashed;
  background: #fcfcfc;
}

.a-result-item__wrapper__header {
  display: inline-block;
  position: relative;
  bottom: -20px;
  left: 7px;
  padding: 2px 7px 3px;
  line-height: 1;
  font-size: 11px;
  font-weight: bold;
  background: @white;
  border: 1px solid @gray-border;
  border-radius: 10px;
}

.a-result-item {
  display: flex;
  position: relative;
  align-items: center;
}

.a-result-item__label {
  display: inline-block;
  margin-top: 3px;
  padding: 3px 6px;
  font-size: 11px;
  color: @white;
  font-weight: bold;
  line-height: 1;
  border-radius: 10em;
  background: @red;
  cursor: default;

  .glyphicons {
    margin-left: 4px;
    font-size: 10px;
  }
}

.a-result-item__source {
  position: absolute;
  top: -17px;
  left: -15px;
  padding: 0 5px;
  font-size: 11px;
  color: @gray;
  background: @white;
  border-radius: 3px;
}

.a-result-item__carrier {
  width: 75px;
}

.a-result-item__logo {
  width: 30px;
  height: 30px;
  background-size: 30px;
  background-position: center;
  background-repeat: no-repeat;
}

.a-result-item__logo--mutiple {
  display: inline-block;
  width: 23px;
  height: 23px;
  margin: 2px 10px 4px 0;
  background-size: 23px;
}

.a-result-item__time {
  width: 205px;
  font-size: 16px;
}

.a-result-item__num {
  margin: 2px 10px 0 0;
  font-size: 12px;
  color: @gray;
}

.a-result-item__details {
  width: 125px;
}

.a-result-item__total {
  margin-top: 5px;
  font-size: 12px;
}

.a-result-item__seats {
  flex: 1;
  flex-direction: column;
  align-self: stretch;
  margin: -9px 0;
  padding: 8px 20px 8px 8px;
  background: @gray-bg;
  border-radius: 0 4px 4px 0;
}

.a-result-item__seats__section {
  display: flex;
  flex: 1;
  font-family: monospace;

  & + & {
    margin-top: 4px;
    padding-top: 4px;
    border-top: 1px dashed @gray-border;
  }
}

.a-result-item__seats__list__title {
  margin-right: 10px;
  padding: 1px 0;
  font-size: 12px;
  white-space: nowrap;

  .highlight-red {
    text-decoration: line-through;
  }

  .text-xs {
    margin-top: 1px;
  }
}

.a-result-item__seats__list__title__icons {
  display: inline-block;
  position: relative;
  top: -1px;
  margin-left: 8px;
}

.a-result-item__seats__list {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 12px;

  li {
    min-width: 20px;
    padding: 1px 0;
    text-align: center;
  }
}

.a-result-item__seats__list--highlighted {
  color: @white;
  background: @green;
}

.a-separated-tickets {
  display: flex;
  align-items: stretch;
  font-size: 11px;
}

.a-separated-tickets__message {
  font-size: 20px;
  text-align: center;
}

.a-separated-tickets__destination {
  flex-grow: 1;
  margin: 10px 0;
  padding: 9px 0 9px 15px;
  border: 1px solid @gray-border;
  border-radius: 4px;
}

.a-separated-tickets__change-flight {
  padding: 0;
  font-weight: bold !important;
  font-size: 12px;
  position: absolute;
  top: 10px;
  right: 15px;
}

.a-separated-tickets__price {
  position: absolute;
  bottom: 10px;
  right: 15px;
  font-weight: bold;
  font-size: 16px;
}

.a-separated-tickets__details_row {
  line-height: 22px;
}

.a-separated-tickets__destination--selected {
  background: #f9fafa;
  cursor: pointer;
  width: 0;
  position: relative;
}

.a-separated-tickets__destination--not-selected {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.separated-tickets__title {
  font-weight: bold;
  font-size: 14px;
}

.icon-separated-tickets-next {
  margin: 0 10px;
  align-self: center;
}
