.s-map__switcher {
  text-align: center;
  padding-left: 25px;
  margin: -10px 0 25px;

  .btn {
    margin: 0 6px;
  }

  .btn-default {
    color: #222;
  }

  .btn-danger {
    pointer-events: none;
  }
}

.s-map__details {
  margin: 0 0 36px;
  padding-left: 25px;
  font-size: 12px;
  text-align: center;
}

.s-map__legend {
  margin: 70px 0 20px;
  padding: 0 10px;
  text-align: right;
  list-style: none;

  li {
    margin: 5px 0;
  }
}

.s-map__legend__item {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 8px;
  vertical-align: middle;
  background-color: #cbcbcb;
  border-radius: 2px;
  cursor: default !important;
}

.s-map__container {
  margin: 0 0 60px;
}

.s-map__row {
  display: flex;
  position: relative;
  padding-left: 25px;
  justify-content: center;
}

.s-map__item {
  display: flex;

  .glyphicons {
    display: flex;
    align-self: center;
  }
}

.s-map__item--col {
  width: 28px;
  height: 32px;
  margin-top: 18px;
  padding-top: 4px;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  color: #2b343d;
  border-top: 3px solid #2b343d;
}

.s-map__item--index {
  position: relative;
  width: 65px;
  margin: 0 0 0 -16px;
  padding: 0 0 0 16px;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: #2b343d;
  z-index: 5;

  &:first-child {
    margin: 0 -16px 0 0;
    padding: 0 16px 0 0;
    justify-content: flex-end;
  }
}


.s-map__item--wing-front {
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    right: 0;
    bottom: -14px;
    left: 0;
    background: #f4f3f4;
    z-index: -1;
    .skew(0deg, 20deg);
  }

  &:first-child {
    &:after {
      .skew(0deg, -20deg);
    }
  }
}

.s-map__item--wing {
  background: #f4f4f4;
}

.s-map__item--side {
  width: 3px;
  margin: 0 16px;
  align-items: stretch;
  background: #e3e3e3;
}

.s-map__item--exit {
  height: 40px;
  background: @red;
}

.s-map__item--seat {
  width: 24px;
  height: 24px;
  margin: 2px;
  align-self: flex-end;
  justify-content: center;
  background: #cbcbcb;
  border-radius: 3px;
}

.s-map__item--available {
  background: #75c44c;
  cursor: pointer;

  &:hover {
    background: lighten(#75c44c, 12%);
  }
}

.s-map__item--out-of-policy {
  position: relative;
  top: 0;

  &:before {
    .glyphicon();
    content: "\e101";
    position: absolute;
    top: 50%;
    right: 50%;
    margin-right: 0;
    font-size: 12px;
    color: @red;
    transform: translate(50%, -50%);
  }
}

.s-map__item--leg-space {
  background: #fad033;
  cursor: pointer;

  &:hover {
    background: lighten(#fad033, 12%);
  }
}

.s-map__item--selected {
  background: @purple;
  cursor: pointer;

  &:hover {
    background: lighten(@purple, 12%);
  }

  .glyphicons {
    color: @white;
  }
}

.s-map__item--aisle {
  width: 30px;
}

.s-map__item--empty {
  pointer-events: none;
  background: transparent !important;
}

.s-map__item__class {
  position: absolute;
  right: 16px;
  top: 18px;
  padding: 5px 7px;
  font-size: 11px;
  white-space: nowrap;
  font-weight: normal;
  color: @white;
  background: #2b343d;
}

.s-map__item__label {
  display: flex;
  margin-left: -12px;
  padding: 2px 3px;
  align-self: center;
  font-size: 10px;
  text-transform: uppercase;
  color: @red;
  font-weight: bold;
  background: white;
  border-radius: 2px;
  z-index: 10;
}

.s-map__item__details {
  position: absolute;
  width: 370px;
  margin-top: 41px;
  padding: 5px 10px;
  line-height: 1.6;
  background: #f4f4f4;
  border: 5px solid @white;
  z-index: 15;
  box-shadow: 0 0 8px rgba(0,0,0,0.18);

  .text-xl {
    font-size: 16px !important;
  }
}

.s-map__item__details__info {
  margin: 5px 0;
  font-size: 11px;
}

.s-map__item__details__table {
  font-size: 11px;

  .table {
    margin-bottom: 5px;
  }

  td, th {
    padding: 7px 10px !important;
  }
}