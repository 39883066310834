// Checkout (AFT)

.rev-checkout__top-bar {
  padding: 5px 0 10px
}

.rev-checkout__alerts {
  margin: -3px 0 -1px;
}

.rev-checkout__content {
  .loading-spinner {
    width: 18px;
    height: 18px;
    margin-top: 6px;
    border: 3px solid @gray-dark;
    border-right-color: transparent;
  }
}

.step-section {
  background: @white;
  border: 1px solid @gray-border;

  & + .step-section {
    border-top: none;
  }

  .section-selector {
    width: 690px;
    margin-top: -10px;
  }

  .form-group--well {
    margin: 0 0 15px !important;
    background: @gray-bg;

    .control-label {
      width: 203px !important;
    }
  }
}

.step-section--collapsed {
  .step-section__header {
    .opacity(.85);
    border-bottom: none;
  }

  .step-section__content,
  .step-section__header__note,
  .step-section__footer {
    display: none;
  }

  .step-section__header__control {
    display: block;
  }
}

.step-section--skipped {
  .step-section__header {
    color: lighten(@gray, 25%);
  }

  .step-section__header__num {
    background: lighten(@gray, 30%) !important;
  }

  .step-section__skip-message {
    display: block;
    color: @gray;
  }
}

.step-section__header {
  position: relative;
  padding: 22px 30px 20px 70px;
  line-height: 1;
  font-size: 18px;
  border-bottom: 1px dashed @gray-border;
  cursor: default;

  &_unmarked {
    border-bottom: 1px solid @gray-border;
    padding: 22px 30px 20px 30px;
  }
}

.step-section__header__num {
  position: absolute;
  top: 50%;
  left: 29px;
  width: 26px;
  margin: -13px 0 0;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  font-weight: bold;
  color: @white;
  background: @blue;
  border-radius: 10em;
}

.step-section__header__note {
  float: right;
  margin-top: 4px;
  font-size: 11px;
  color: @gray;
}

.step-section__skip-message {
  display: none;
  float: right;
  margin-top: 3px;
  font-size: 12px;
  font-style: italic;
}

.step-section__header__control {
  display: none;
  float: right;
  margin-top: -5px;

  .btn {
    padding: 4px 16px;
  }
}

.step-section__content {
  padding: 35px 30px;
}

.step-section__content__title {
  margin: 30px 0 20px;
  font-size: 15px;
  font-weight: bold;

  &:first-child {
    margin-top: 0;
  }
}

.step-section__block__header {
  margin: 35px -10px 12px;
  padding: 6px 12px;
  font-size: 12px;
  background: #ecf2f4;
  border-radius: 3px;

  &:first-child {
    margin-top: -15px;
  }
}

.step-section__block__header--wrapped {
  margin-top: 0 !important;
}

.step-section__content__sub-title {
  margin-bottom: 6px;
  font-size: 13px;
  font-weight: bold;
}

.step-section__content__message {
  margin: 30px -30px -36px -30px;
  padding: 25px 30px 15px;
  background: lighten(@yellow, 32%);
  border-bottom: 1px solid @gray-border;

  li + li {
    margin-top: 10px;
  }
}

.step-section__content__message__item {
  margin-bottom: 3px;
  padding: 6px 10px;
  background: lighten(@yellow, 37%);
  border: 1px dashed @gray-border;
}

.step-section__hr {
  margin: 17px -30px;
}

.step-section__content__footer {
  margin: 35px -30px -10px;
  padding: 20px 30px 0;
  border-top: 1px dotted @gray-border;

  .step-section__content__title {
    margin-bottom: 10px;
  }
}

.step-section__footer {
  .clearfix();
  padding: 15px 30px;
  background: #f7f9fb;
  border-top: 1px solid #e1e6ed;

  .btn {
    min-width: 90px;
    padding: 8px 16px;
    font-size: 15px;
    font-weight: bold;
  }

  .btn-primary[disabled] {
    background: lighten(@gray, 30%);
    border-color: lighten(@gray, 30%);
  }
}

.rev-checkout__sidebar {
  .section-wrapper {
    padding-bottom: 0;
  }
}

.rev-checkout__sidebar__header {
  margin: -11px -13px 5px;
  padding: 14px 16px;
  line-height: 1;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px dashed @gray-border;
}

.c-checkout__details {
  .clearfix();
  margin: -5px -13px 1px;
  padding: 10px 16px;
  background: @gray-bg;
  border-bottom: 1px dashed @gray-border;


  h2 {
    margin: 1px 0 6px;
    font-size: 14px;
    font-weight: bold;
  }

  .c-content__item__car__label {
    margin-top: -4px;
  }

  .c-content__item__car__model {
    width: auto;
    max-width: 77%;
  }

  .acriss-code {
    top: -7px;
    margin-left: 7px;
  }
}

.c-checkout__details__left-col {
  float: left;
  width: 68%;

  .c-content__item__car__model {
    margin-bottom: 10px;
  }
}

.c-checkout__details__right-col {
  float: right;
  width: 32%;
  padding-top: 2px;
  text-align: right;
}

.c-checkout__details__sub {
  float: left;
  width: 100%;
  font-size: 11px;
}

.checkout__info {
  font-size: 12px;

  > hr {
    margin: 0 -13px;
  }

  .alert {
    margin: 8px 0;
    padding: 5px 10px;
    font-size: 11px;
  }

  .loading-spinner {
    margin-top: -6px;
    margin-right: 7px;
    border: 3px solid @gray-dark;
    border-right-color: transparent;
  }
}

.checkout__info__toggle {
  .text-overflow();
  height: 19px;
}

.checkout__info__toggle--full {
  height: auto;
  white-space: normal;
}

.checkout__info__section {
  .clearfix();
  margin: 0 -13px 0;
  padding: 9px 16px;

  ul {
    margin-top: 5px;
  }
}

.checkout__info__section__title {
  float: left;
  width: 60%;

  .btn-link--narrow {
    padding: 0;
    line-height: 1;
    font-size: 12px;
  }
}

.checkout__info__section__value {
  float: right;
  width: 40%;
  text-align: right;

  [class^="icon-am"],
  [class*=" icon-am"] {
    position: relative;
    top: 2px;
    margin-top: -3px;
  }

  .c-rate-ico {
    float: none;
    display: inline-block;
    top: -3px;
    height: 24px;
    margin: -1px 0 -6px 3px;
    line-height: 24px;

    &:last-child {
      margin-right: 0;
    }

    &:after {
      display: none !important;
    }
  }

  .muted {
    color: #888;
  }

  .glyphicons-airplane,
  .glyphicons-map-pin {
    margin-right: 5px;
    color: @gray;
  }
}

.checkout__info__section__value--destination {
  display: block;
  margin-top: 2px;
  line-height: 1.3;
  font-size: 11px;
}

.checkout__info__section--wide-value {
  .checkout__info__section__title {
    width: 30%;
  }

  .checkout__info__section__value {
    width: 69%;
  }
}

.checkout__info__thumb {
  float: left;
  width: 75px;
  height: 75px;
  background-size: cover;
}

.checkout__info__hotel {
  float: right;
  width: 72%;

  h3 {
    margin: -3px 0 3px;
    font-size: 13px;
    font-weight: bold;
    line-height: 1.3;
  }
}

.checkout__info__header__addr {
  font-size: 11px;
  color: @gray;
}


.checkout__info__row {
  .clearfix();
  margin: 4px 0;
}

.checkout__info__col {
  float: left;
  margin: 0 6px 0 0;

  [class^="ico-"],
  [class*=" ico-"] {
    margin-top: 3px;
  }
}

.checkout__info__table {
  margin-bottom: 3px !important;

  td {
    padding: 0 0 3px !important;
    border: none !important;
  }

  hr {
    margin: 4px 0;
  }

  .well & {
    margin-bottom: -1px !important;
    background: transparent;
  }
}

.checkout__info__note {
  margin-bottom: 11px;
  font-size: 11px;
  color: @gray;
}

.checkout__info__footer {
  .clearfix();
  position: relative;
  margin: 25px -13px 0;
  padding: 0 13px;
  border-top: 1px solid @gray-border;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 4px;
    bottom: 4px;
    width: 1px;
    border-left: 1px dotted @gray-border;
  }
}

.checkout__info__footer__col {
  float: left;
  width: 50%;
  padding: 14px 0;
  text-align: center;
  font-size: 12px;
}

.checkout__info__footer__value {
  margin-top: 5px;
  font-size: 18px;
  font-weight: bold;
  color: @gray-darker;
}

.checkout__info__total {
  margin: 3px -13px 0;
  padding: 13px 13px;
  text-align: right;
  border-top: 1px solid @gray-border;
}

.checkout__info__total__title {
  margin: 0 0 2px;
  font-size: 12px;
  text-transform: uppercase;
}

.checkout__info__total__value {
  font-size: 21px;
  font-weight: bold;

  .loading-spinner {
    width: 16px;
    height: 16px;
  }
}

.section-selector {
  display: flex;
  margin: 0 0 23px 0;
  border-bottom: 1px solid @orange;
}

.section-selector__item {
  padding: 4px 20px 3px;
  cursor: pointer;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  &:hover:not(.section-selector__item--selected) {
    background: darken(@gray-bg, 2%);
    border-bottom: 2px solid @gray-bg;
  }
}

.section-selector__item--selected {
  border-bottom: 2px solid @orange;

  label {
    font-weight: bold;
  }
}

.cc-selector {
  margin: 5px 0 6px 220px;
}

.cc-list {
  padding: 0 0 10px;
}

.cc-list__item {
  margin: 7px 0 10px;

  &:hover {
    .cc-list__header,
    .cc-list__item__type {
      border-color: darken(#cee2f5, 14%);
    }
  }
}

.cc-list__item--selected {
  .cc-list__content {
    display: block;
  }

  .cc-list__header {
    background: #d7eaf8;
    border: 2px solid #adceed !important;
    cursor: default;
  }

  .cc-list__item__type {
    border-color: #adceed !important;
  }
}

.cc-list__item__type {
  margin: -14px -16px 12px -35px;
  padding: 8px 15px;
  line-height: 1;
  font-size: 12px;
  font-weight: bold;
  text-align: right;
  border: 2px solid #cee2f5;
  background: #e9f3fb;
  border-radius: 8px 8px 0 0;
}

.cc-list__header {
  position: relative;
  padding: 12px 14px 12px 33px !important;
  background: #f6fafd;
  border: 2px solid #cee2f5;
  border-radius: 8px;
  z-index: 5;

  .radio__icon {
    margin-top: 2px;
  }

  > .pull-right {
    .btn {
      margin-top: -2px;
      margin-left: 15px;
    }
  }
}

.cc-list__header__cc {
  display: inline-block;
  width: 38%;
  vertical-align: top;
}

.cc-list__header__name {
  display: inline-block;
  width: 32%;
  vertical-align: top;
}

.cc-list__content {
  display: none;
  position: relative;
  margin-top: -6px;
  padding: 25px 20px 20px 33px;
  background: #f9fcff;
  border: 1px solid #adceed;
  border-top: none;
  border-radius: 0 0 8px 8px;
  z-index: 2;

  .control-label {
    width: 175px;
  }

  .step-section__content__sub-title {
    margin-top: 5px;
    margin-bottom: 13px;
  }

  .billing-form__control {
    margin-left: 175px;
  }

  .billing-form__title {
    background: #f9fcff;
  }

  .form-group__label-offset:before {
    width: 160px;
  }
}

.cc-list__footer {
  .clearfix();
  margin: 5px 0 25px;
  padding: 1px 0;
  border-top: 1px solid @gray-border;

  .btn {
    font-weight: bold !important;
  }
}


.billing-form__control {
  position: relative;
  margin: 10px 0 15px 220px;
  padding: 20px 25px 10px;
  border: 1px solid @gray-border;

  .btn {
    font-weight: bold;
    color: #333;
  }
}

.billing-form__title {
  position: absolute;
  top: -8px;
  left: 15px;
  margin: 0;
  padding: 0 10px;
  font-size: 12px;
  font-weight: bold;
  background: @white;
}

.billing-form__text {
  margin: 10px 0 0;
  color: @gray;
  font-size: 11px;
}

.checkout-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mask {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
}

.drawer {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  flex-direction: column;
  background: @white;
  border: 1px solid @grayBorder;
  width: 600px;
  height: 100%;
  overflow-y: auto;
  z-index: 1000;
}

.drawer__header {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid @grayBorder;
}

.drawer__header-title {
  font-size: 18px;
  font-weight: 700;
}

.cross-icon {
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cg clip-path='url(%23clip0_894_133)'%3E%3Cpath d='M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z' fill='%2319191A'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_894_133'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  cursor: pointer;
}

.drawer__body {
  padding: 20px;
}

.drawer__item {
  border: 2px solid @grayBorder;
  border-radius: 5px;
  margin-bottom: 10px;

  &:hover {
    border: 2px solid @btn-default-color;
  }
}

.item-header {
  padding: 10px 30px;
  background: #F7F7F7;
  border-radius: 5px 5px 0px 0px;
  border-bottom: 1px solid @grayBorder;
}

.sub-title {
  display: flex;
  margin-top: 9px;
  align-items: center;
}

.sub-title__value {
  font-size: 12px;
  margin-right: 5px;
}

.sub-title__price {
  margin-left: auto;
  font-size: 16px;
  font-weight: 700;
  color: @green;
}

.item-body {
  padding: 15px 30px;
}

.item-body__content {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}

.branded-fares {
  gap: 10px;

  .h-item__policy {
    margin-left: 10px;
  }
}

.branded-fares, .branded-fares__selection-popup {
  .h-item__policy {
    position: relative;
    top: -2px;
  }
}

.branded-fare {
  display: flex;
  flex-direction: column;
  flex-basis: calc(50% - 5px);
  padding: 20px 20px 15px !important;

  & > hr {
    width: 100%;
  }

  .a-result-item__carrier {
    width: 50px;
  }

  .a-result-item__logo {
    width: 35px;
    height: 35px;
    background-size: 35px;
  }

  .a-content__item__rate-details {
    padding-left: 0;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
  }
}

.branded-fare__arrival-departure {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  flex-grow: 1;

  .a-result-item__time {
    font-size: 14px;
  }
}

.branded-fare__content {
  padding-left: 50px;
  margin-top: -8px;
}

.branded-fare__price-row {
  line-height: 35px;
}

.branded-fare__price {
  color: @green;
  font-weight: bold;
  font-size: 16px;
}

.branded-fare__change-fare-block {
  margin-top: auto;
}

.branded-fare__change-fare-btn {
  margin-top: -8px;
  width: 100%;
}

.branded-fares__selection-popup {
  left: initial;

  .modal-dialog {
    margin: 0 !important;
  }

  .modal-content {
    height: 100vh;
  }

  .close {
    font-size: 28px;
    margin-top: -4px;
  }

  .modal-title {
    font-weight: bold;
  }

  .selector-grid__item {
    width: 100%;
    margin: 0 0 15px 0;
  }

  .a-content__item__rate-details {
    display: flex;
    justify-content: space-between;
  }

  .selector-grid__title, .selector-grid__sub-title {
    padding-left: 16px;
  }

  .selector-grid__price, .selector-grid__price .text-sm {
    color: @green;
    font-size: 17px !important;
    font-weight: bold;
  }

  .selector-grid__item:hover {
    border-color: @blue;
  }

  .a-content__item__rate-details {
    font-size: 13px;
  }
}

.branded-fares__multiple-title {
  line-height: 1.5;
}

.branded-fares__selection-popup.fade.show {
  transform: none;
}

.branded-fares__selection-popup.fade {
  transition: transform 0.3s ease-in-out;
  transform: translate(125%, 0px);
}

.branded-fares__selection-popup.fade {
  opacity: 1 !important;
}

.separated-tickets-form-group {
  padding-bottom: 15px;

  .delimiter {
    margin-right: 5px;
  }

  .form-group__sub > .btn-link--narrow {
    font-weight: bold !important;
  }

  .separated-tickets-form-group__ticket-title {
    padding-bottom: 20px;
  }
}
