.hotel-tab__best-rates {
  margin: 30px 0 0;
}

.best-rates {
  margin-bottom: 15px !important;
  font-size: 12px;
  table-layout: fixed;
  border-collapse: separate;

  tr {
    &:last-child {
      td,
      th {
        border-bottom: 1px solid @table-border-color;
      }

      .best-rates--highlighted {
        border-bottom: 1px solid #27b10f;
      }
    }
  }

  th,
  td {
    position: relative;
    padding-right: 11px !important;
    padding-left: 11px !important;
    border-left: 1px solid @table-border-color;

    &:last-child {
      border-right: 1px solid @table-border-color;
    }
  }

  th {
    width: 18%;
    text-align: right;
    background: #f1f7fb;
  }

  .h-rate__details {
    margin: 0;
  }

  .h-rate__details__item {
    display: block;
  }

  .rev-provider-ico {
    margin: 0 4px 0 0;

    &:after {
      display: none;
    }
  }

  .h-rate__code {
    float: right;
    position: relative;
    top: 1px;
    margin: 0;
    font-size: 12px;
  }

  .h-rate__cxl__value {
    margin: 0;
  }

  .h-rate__options {
    margin: 0;
    padding: 0 2px 0 0;
    border-bottom: none;

    .btn {
      margin: -2px 0 0 4px;
      font-size: 9px;
    }
  }
}

.best-rates--highlighted {
  border-left: 1px solid #27b10f !important;
  border-right: 1px solid #27b10f !important;

  .best-rates__label {
    display: block;
  }
}

.best-rates--unstyled {
  border-top: none !important;
  padding-top: 0 !important;
  padding-bottom: 5px !important;

  .best-rates__info {
    margin-top: -1px;
  }
}

.best-rates--empty {
  text-align: center;
  vertical-align: middle !important;
}

.best-rates__empty-label {
  position: relative;
  color: lighten(@gray, 19%);
  line-height: 1;
  font-size: 17px;
  z-index: 5;
}

.best-rates__loader {
  position: relative;
  margin: 25px 0 20px;
  z-index: 5;

  .loading-spinner {
    width: 17px;
    height: 17px;
    border: 3px solid lighten(@gray, 42%);
    border-right-color: transparent;
  }
}

.best-rates__loader__text {
  margin-top: 19px;
  font-size: 11px;
  color: @gray;
}

.best-rates__label {
  display: none;
  position: absolute;
  right: -1px;
  top: -29px;
  left: -1px;
  height: 29px;
  padding: 7px 6px;
  font-size: 11px;
  text-align: center;
  color: @white;
  background: #27b10f;
  border-radius: 2px 2px 0 0;
}

.best-rates__title {
  font-size: 11px;
  text-align: center;
  font-weight: bold;
}

.best-rates__rate-label {
  display: inline-block;
  margin-top: 8px;
  padding: 2px 6px;
  font-size: 12px;
  color: @white;
  background: #8992a0;
  border-radius: 3px;
}

.best-rates__rate-label--blue {
  color: @white !important;
  background: @blue;
  border: none !important;
}

.best-rates__aside {
  margin: 4px 0 2px;
  padding: 3px;
  line-height: 1;
  font-size: 11px;
  text-align: center;
  color: darken(@purple, 12%);

  &:empty {
    display: none;
  }
}

.best-rates__aside--danger {
  color: @red;
}

.best-rates__aside--gray {
  color: @gray;
}

.best-rates__dropdown {
  position: absolute;
  right: 3px;
  top: 3px;

  .dropdown-toggle {
    font-size: 13px;
    padding: 1px 6px;
    line-height: 1;
    color: lighten(@gray, 15%);
    background: @gray-bg;
    border-color: transparent;

    &:hover {
      color: @gray-dark;
      background: darken(@gray-bg, 5%);
    }
  }
}

.best-rates__price {
  margin: 7px 0 0;
  text-align: center;

  .small {
    font-size: 11px;
  }

  .btn {
    min-width: 75px;
    margin: 12px 0 6px;
    padding: 6px 10px;
  }

  .best-rates__btn-init {
    min-width: auto;
    margin-left: 10px;
  }

  & > button.highlight-orange.btn-link:focus {
    color: @orange;
  }
}

.best-rates__price__value {
  position: relative;
  margin-bottom: 1px;
  font-size: 23px;

  .hotel-rate__range {
    position: absolute;
    left: 5px;
    top: 0;
  }
}

.best-rates__price__currency {
  top: -6px;
  margin-right: -3px;
  font-size: 14px;
}

.best-rates__details {
  height: 20px;
  margin: 7px 0 2px;

  .btn {
    position: absolute;
    bottom: 10px;
  }
}

.best-rates__info {
  .clearfix();
  margin: 6px 0 0;
  padding: 5px 0 0;
  border-top: 1px dotted @gray-border;

  &.text-gray {
    color: lighten(@gray, 20%);
  }

  .glyphicon {
    font-size: 11px;
  }

  .btn {
    white-space: normal;
    text-align: left;
  }
}

.best-rates__footer {
  margin: -6px 0 -1px;
  padding-bottom: 9px;
  font-size: 11px;
  color: lighten(@gray, 5%);
  text-align: right;
  border-bottom: 1px solid @gray-border;
}

.section-banners:not(:empty) ~ .section-hotels {
  .best-rates th {
    display: none;
  }
}
