.float-panel {
  position: fixed;
  left: 50%;
  bottom: 0;
  height: 390px;
  width: 500px;
  margin-left: 190px;
  background: @white;
  border-left: 4px solid @green;
  box-shadow: 0 0 7px rgba(0,0,0,0.15);
  z-index: 1001;
  .transition(all 0.3s);
}

@media (max-width: 1405px) {
  .float-panel {
    left: auto;
    right: 29px;
    margin-left: 0;
  }
}


.float-panel--wide {
  right: 5%;
  left: auto;
  width: 440px;
}

.float-panel--minimized {
  bottom: -351px;

  .float-panel__header {
    &:after {
      border-top: none;
      border-right: 5px solid transparent;
      border-bottom: 5px solid @gray;
      border-left: 5px solid transparent;
    }
  }
}

.float-panel--maximized {
  width: auto;
  height: auto;
  margin: auto;
  top: 12%;
  right: 12%;
  bottom: 0;
  left: 12%;
  box-shadow: 0 0 40px rgba(0,0,0,0.5);


  .float-panel__body {
    .result-block {
      margin: 13px;
    }

    pre {
      padding: 20px;
      font-size: 12px;
    }
  }
}

.float-panel--maximized + .float-panel__bg {
  display: block;
}

.float-panel__bg {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.7);
  z-index: 4;
}

.float-panel__header {
  position: relative;
  height: 40px;
  padding: 10px 13px 0;
  border-bottom: 1px solid @gray-border;
  cursor: pointer;
  .transition(background 0.3s);

  &:hover {
    background: darken(@white, 5%);
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 16px;
    margin-top: -2px;
    border-top: 5px solid @gray;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }
}

.float-panel__header__controls {
  float: right;
  position: relative;
  top: 2px;
  color: @gray;

  .glyphicon {
    margin-left: 3px;
    padding: 2px;
    cursor: pointer;

    &:hover {
      color: @gray-darker;
    }
  }
}

.float-panel__header--control-panel {
  background: @white !important;
  cursor: default;

  &:after {
    display: none;
  }
}

.float-panel__title {
  float: left;
  width: 62%;
  margin: 0;
  font-weight: bold;
  font-size: 13px;
}

.float-panel__aside {
  float: left;
  width: 32%;
  margin-top: 3px;
  font-size: 11px;
  color: @gray;
}

.float-panel__body {
  position: absolute;
  top: 40px;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;

  .result-block {
    margin: 6px;
    padding: 7px 1px 0;
    border-radius: 3px;
  }

  pre {
    margin: 5px;
    font-size: 10px;
    line-height: 1.6;
    color: @gray-darker;
  }
}

.float-panel__body__message {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255,255,255,0.68);
  z-index: 5;

  .glyphicon {
    position: absolute;
    left: 20px;
    top: 50%;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    line-height: 40px;
    font-size: 20px;
    color: @white;
  }
}

.float-panel__body__message__wrapper {
  position: relative;
  margin: 85px 0 0;
  padding: 10px 10px 10px 65px;
  font-size: 16px;
  line-height: 1.4;
  color: @white;
  background: @green;
}

.float-panel__item {
  .clearfix();
  position: relative;
  margin: 0 13px 5px;
  padding: 7px 13px 8px 0;
  font-size: 11px;
  line-height: 1.35;
  border-bottom: 1px solid @gray-border;

  &:hover {
    .float-panel__item__close {
      display: block;
    }
  }

  h5 {
    margin: 0;
    font-weight: bold;
    font-size: 12px;
    line-height: 1.35;
  }
}

.float-panel__item__title {
  padding-bottom: 4px;
  margin-bottom: 4px;
  font-size: 11px;
  border-bottom: 1px dotted @gray-border;
}

.float-panel__item__price {
  float: left;
  width: 15%;
  font-size: 12px;

  .btn {
    margin: 8px 0 0;
  }
}

.float-panel__item__content {
  float: right;
  width: 80%;
}

.float-panel__item__row {
  margin-bottom: 2px;
}

.float-panel__item__small {
  color: darken(@gray, 10%);
  font-size: 11px;
}

.float-panel__item__close {
  display: none;
  position: absolute;
  right: -3px;
  top: 7px;
  color: @red;
  font-size: 9px;
  padding: 2px;
  cursor: pointer;

  &:hover {
    color: darken(@red, 15%);
  }
}

.float-panel__message {
  margin: 28% auto 0;
  padding: 0 20px;
  text-align: center;

  p {
    font-size: 15px;
    margin-bottom: 20px;
  }
}

.float-panel__footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 52px;
  padding: 0 13px;
  text-align: right;
  background: @gray-bg;
  border-top: 1px solid @gray-border;

  & + .float-panel__body {
    bottom: 52px;
  }

  .btn {
    float: right;
    margin-top: 10px;
    padding: 7px 15px;
    font-size: 12px;
    border: none;
  }
}
