@keyframes progressBar {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }

  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }

  85% {
    right: 0%;
    left: 75%;
    width: 25%;
  }

  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}

.float-preloader {
  position: fixed;
  left: 40px;
  bottom: -100px;
  max-width: 344px;
  padding: 14px 32px 18px;
  background: @white;
  border-radius: 14px;
  box-shadow: 0 0 29px rgba(0,0,0,0.16);
  z-index: 10;
  transition: bottom 0.5s;

  &.in {
    bottom: 80px;
    transition: bottom 0.5s;
  }

  &.out {
    bottom: -120px;
    transition: bottom 0.5s;
  }
}

.float-preloader__bar {
  position: relative;
  width: 280px;
  height: 8px;
  margin: 2px 0 14px;
  background: @gray-bg;
  border-radius: 10px;
}

.float-preloader__bar__track {
  position: absolute;
  top: 2px;
  right: 100%;
  bottom: 0;
  left: 0;
  width: 0;
  height: 4px;
  border-radius: 10px;
  background: @orange;
  animation: progressBar 1.6s linear infinite;
}
