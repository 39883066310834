.c-sidebar,
.a-sidebar {
  float: left;
  width: 20.5%;
  padding: 26px 0 20px;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    margin-left: -317px;
    margin-bottom: 57px;
    background: @white;
    border-left: 1px solid @gray-border;
    z-index: -1;
  }

  .section-wrapper--border {
    margin: 0 0 18px 0;
    padding: 6px 5px 6px 10px;
    font-size: 12px;
    border-left: 3px solid @gray-dark;

    &:before {
      display: none;
    }
  }

  .leaflet-control-attribution.leaflet-control {
      display: none;
  }
}

.a-sidebar {
  padding-top: 19px;
}

.c-sidebar__item {
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid @gray-border;

  .slider__info {
    margin-bottom: 5px;
  }

  .slider__value {
    color: #333;
  }

  .rc-slider {
    height: 18px;
  }

  .rc-slider-disabled {
    pointer-events: none;
    .opacity(0.5);
  }

  .rc-slider-rail {
    height: 5px;
    background: @white;
    border: 1px solid #333;
  }

  .rc-slider-track {
    height: 5px;
    background-color: #333;
  }

  .rc-slider-handle {
    margin-top: -5px;
    background: #333;
    box-shadow: 0 0 0 3px white;
  }
}

.c-sidebar__item--disabled {
  pointer-events: none;
  .opacity(0.5);
}

.c-sidebar__trigger-wrapper {
  margin: 0 0 21px;

  .btn-default--outline {
    margin-top: 5px;
    padding: 7px 0;
  }
}

.c-sidebar__item__header {
  margin-bottom: 11px;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;

  .btn {
    padding: 2px 0;
    font-size: 11px;
    float: right;
  }
}

.c-sidebar__item__list {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    .clearfix();
    margin-bottom: 5px;
  }

  .checkbox {
    float: left;
    width: 85%;
    margin: 0;
  }

  [class^="icon-"],
  [class*=" icon-"] {
    width: 29px;
    margin: 0 2px 0 -2px;
  }

  [class^="ico-"],
  [class*=" ico-"] {
    vertical-align: top;
  }
}

.c-sidebar__item__counter {
  float: right;
  width: 30px;
  margin-top: -1px;
  line-height: 18px;
  font-size: 11px;
  text-align: center;
  background: rgba(255,255,255,0.7);
  border-radius: 2px;
}

.c-sidebar__map {
  position: relative;
  height: 55px;
  background-color: #fafafa;
  border: 3px solid @white;

  &:hover {
    border-color: @blue;

    .c-sidebar__map__text {
      color: @white;
      background: @blue;
    }
  }

  .btn {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 10;
  }
}

.c-sidebar__map__text {
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 4px 7px 1px;
  color: @blue;
  font-size: 12px;
  background: @white;
}

.c-map-trigger-lnk {
  position: relative;
  height: 52px;
  background: #fafafa url(../img/map-sm-bg.png) no-repeat 0;
  border: 3px solid @white;

  &:hover {
    box-shadow: 0 0 14px rgba(30,30,105,0.1);

    .c-map-trigger-lnk-text {
      color: @gray-dark;
    }
  }

  .btn {
    position: absolute;
    top: -3px;
    bottom: -3px;
    width: 100%;
    z-index: 10;
  }
}

.c-map-trigger-lnk-text {
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 4px 8px 1px;
  color: @blue;
  font-size: 12px;
  background: @white;
}

.slider__switcher {
  display: table;
  width: 100%;
  margin: 13px 0 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.slider__switcher__item {
  display: table-cell;
  width: 50%;
  padding: 6px 2px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  color: @gray-dark;
  border: 1px solid lighten(@gray-border, 7%);
  background: rgba(255,255,255,0.3);

  &:first-child {
    border-right: none;
    border-radius: 3px 0 0 3px;
  }

  &:last-child {
    border-radius: 0 3px 3px 0;
  }

  &:hover:not(.slider__switcher__item--active) {
    background: rgba(255,255,255,0.7);
  }
}

.slider__switcher__item--active {
  color: @gray-darker;
  border-color: @gray-border;
  background: rgba(255,255,255,0.9);
}

.slider__sub-header {
  margin: 21px 0 9px;
}
