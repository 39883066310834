#MfpEmbed_Button_Container_Collapse {
  width: 220px !important;
  margin-bottom: 250px;
  background: transparent;
  transform: rotate(-90deg);
  transform-origin: 100% 0;
  transition-property: none;
}

#MfpEmbed_Button {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;;
  background: #041e5b;
  border-radius: 6px 6px 0 0;
}

#MfpEmbed_Span {
  margin-left: 20px;
  line-height: 1.2;
}

#MfpEmbed_Button_Container {
  margin-bottom: 0;
  border-radius: 6px 6px 0 0;
  transition-property: none;
}
