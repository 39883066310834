.selector-grid__section-title {
  margin: 0;
  padding: 15px 0 0;
  font-size: 18px;
  border-top: 1px solid @gray-border;
}

.selector-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 17px;
  margin: 20px 0 25px;
  padding-bottom: 10px;
  border-bottom: 1px solid @gray-border;
}

.selector-grid__item {
  display: flex;
  flex-direction: column;
  width: 32.3%;
  background: @white;
  border: 2px solid @gray-border;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    border-color: @gray;

    .selector-grid__checkbox {
      border-color: @gray-border;
    }
  }

  &.selector-grid__item--selected {
    border-color: @blue;
    box-shadow: 0 0 10px rgba(0,141,242,0.3);

    .selector-grid__checkbox {
      .glyphicons();
      .glyphicons-ok-2();

      top: 0;
      padding-top: 2px;
      font-size: 12px;
      text-align: center;
      color: @white;
      background: @blue;
      border-color: @blue;
      box-shadow: none;
    }
  }
}

.selector-grid__item--disabled {
  pointer-events: none;
  opacity: 0.6;
  filter: grayscale(50%);
}

.selector-grid__header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 15px 10px;
  background: #f7f7f7;
  border-radius: 5px 5px 0 0;
  height: 50%;

  h3 {
    flex: 1;
    margin: 0;
    font-size: 13px;
  }
}

.selector-grid__header__code {
  position: absolute;
  top: -9px;
  right: 12px;
  padding: 1px 4px;
  color: @gray;
  font-size: 11px;
  background: #f7f7f7;
  border-radius: 3px;
}

.selector-grid__checkbox {
  width: 20px;
  height: 20px;
  margin: -2px 12px 0 0;
  border: 2px solid #fafafa;
  border-radius: 20em;
  background: @white;
  box-shadow: inset 2px 2px 5px rgba(0,0,0,0.17)
}

.selector-grid__title {
  font-weight: bold;
  font-size: 14px;
  line-height: 1;

  .glyphicons {
    top: 0;
    margin-left: 2px;
    font-size: 10px;
    color: lighten(@gray, 10%);
  }
}

.selector-grid__sub-title {
  display: flex;
  align-items: center;
  margin-top: 6px;
  line-height: 1;

  .a-content__item__label {
    margin-top: 0;
  }
}

.selector-grid__value {
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 12px;
  gap: 5px;
}

.selector-grid__price {
  font-size: 15px;
  color: @red;
}

.selector-grid__content {
  padding: 15px 15px 15px;
  border-top: 1px solid @gray-border;
}

.selector-grid__content--list {
  flex-grow: 1;
}

.selector-grid__content__item {
  position: relative;
  padding: 8px 0 0 35px;

  img {
    position: absolute;
    left: 0;
    top: 7px;
    width: 19px;
    height: 19px;
    opacity: 0.9;
  }

  .glyphicons:not(.glyphicons-chat) {
    position: absolute;
    top: 8px;
    left: 3px;
    font-size: 15px;
    color: @gray;
  }

  .glyphicons-chat {
    margin-left: 4px;
    color: @gray;
  }
}

.selector-grid__content__item__value {
  .glyphicons {
    position: relative;
    top: 1px;
    font-size: 10px;
    color: lighten(@gray, 10%);
  }
}

.selector-grid__footer {
  padding: 5px 0;
  border-top: 1px solid @gray-border;
}
