.ag-pnr-modal {
  .ag-home-form {
    width: 520px;
    margin: 0 auto;
    padding: 10px 30px 0;

    .row {
      margin-bottom: 0;
    }

    .control-label {
      font-weight: normal;
    }
  }

  .ag-table th {
    width: 37%;
  }

  .table {
    background: transparent;
  }

  .ag-block-list {
    margin-bottom: 0;
    border-bottom: none;
  }

  .ag-block-list__item .table th {
    width: 35%;
  }

  .ag-table__cell-col {
    width: auto;
  }
}

.ag-pnr-modal__footer {
  .btn-link {
    margin-right: 20px;
  }

  .ag-left-block__offset {
    .clearfix();
    margin: 0;

    .btn-info {
      float: right;
      margin-left: 15px;
    }
  }
}