.c-content__inline-a {
  margin: -10px 0 35px;
  text-align: center;

  img {
    max-width: 728px;
    max-height: 90px
  }
}

.c-content__inline-a--content-footer {
  margin-top: 40px;
}

.c-content__inline-a__item {
  display: inline-block;

  &:first-child {
    margin-right: 50px;
  }

  img {
    max-height: none;
    margin-bottom: 10px;
  }
}

.c-sidebar__item,
.sidebar-item__ad {
  .c-content__inline-a {
    margin: 0;

    img {
      max-width: 200px;
      max-height: none;
    }
  }
}

.sidebar-item__ad {
  broadstreet-zone {
    margin: 12px 0;

    &:empty {
      display: none;
    }
  }
}

.c-content__bar + .c-content__inline-a {
  margin: 15px 20px;
}

.section-hotels {
  .c-content__inline-a {
    margin: 10px 0;
  }

  .section-wrapper {
    .c-content__inline-a {
      margin: 0;
    }

    broadstreet-zone div {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px dashed @gray-border;

      &:empty {
        display: none;
      }
    }
  }
}

